// libraries
import styled from "styled-components";
import { getRadiusBoxShadow } from "~/library";
// types & models
import { EThemeOptions } from "~/theme";

export type IProps = {
    stackHeight: number;
    cards?: React.ReactElement[];
    styleOverwrite?: string;
} & React.HTMLAttributes<HTMLElement>;

export const StyledCardStack = styled.div<IProps>`
    ${props => props.stackHeight === 0 && `
        display: none;
    `}

    height: auto;
    width: 100%;
    
    overflow: hidden;

    background-clip: padding-box;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    ${props => getRadiusBoxShadow(props.theme.colors["drop-shadow"].rgba)}

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const StyledDilineator = styled.div<{styleOverwrite?: string}>`
    height: 1px;
    width: 96%;

    margin: 0 2% 0 2%;
    border-top: 3px solid ${props => props.theme.mode === EThemeOptions.Dark ? "rgba(255,255,255,0.25)" : "rgba(0,0,0,0.25)"};

    background-clip: padding-box;
    overflow: hidden;

    background-color: #fff;

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;