import _accidentPin from "./ic_accident_pin.png";
import _cameraPin from "./ic_camera_pin.png";
import _constructionPin from "./ic_construction_pin.png";
import _eventPin from "./ic_event_pin.png";
import _ferryPin from "./ic_ferry_pin.png";
import _incidentPin from "./ic_incident_pin.png";
import _signPin from "./ic_sign_pin.png";
import _weatherPin from "./ic_weather_pin.png";
import _welcomeCenterOrigin from "./ic_welcomeCenter_origin_pin.png";
import _welcomeCenterOriginClosed from "./ic_welcomeCenter_origin_closed_pin.png";
import _restAreaOrigin from "./ic_restArea_origin_pin.png";
import _restAreaOriginClosed from "./ic_restArea_origin_closed_pin.png";
import _currentLocation from "./ic_current_location_pin.png";

import { EATEventType } from "@algo/network-manager/models/v3"; 

export const accidentPin = _accidentPin;
export const cameraPin = _cameraPin;
export const constructionPin = _constructionPin;
export const eventPin = _eventPin;
export const ferryPin = _ferryPin;
export const incidentPin = _incidentPin;
export const restAreaPin = _restAreaOrigin;
export const restAreaClosedPin = _restAreaOriginClosed;
export const signPin = _signPin;
export const weatherPin = _weatherPin;
export const welcomeCenterPin = _welcomeCenterOrigin;
export const welcomeCenterClosedPin = _welcomeCenterOriginClosed;
export const welcomeCenterOrigin = _welcomeCenterOrigin;
export const restAreaOrigin = _restAreaOrigin;
export const currentLocation = _currentLocation;

export const mapEventTypeToPin = (type: EATEventType) => {

    switch(type){
        case EATEventType.Roadwork:
            return constructionPin;
        case EATEventType.Crash:
            return accidentPin;
        case EATEventType.Incident:
            return incidentPin;
        case EATEventType.RegionalEvent:
            return eventPin;
        case EATEventType.RoadCondition:
            return weatherPin;
        case EATEventType.Unknown:
            return incidentPin;
        default:
            return incidentPin;
    }

}