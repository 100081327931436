// libraries
import * as React from "react";
import { ILocationProps } from "./index";

export const LocationRegion: React.FC<ILocationProps> = (props) => {

    const {width, height, viewBox, color} = props;

    return (

        <svg 
            width={width || "300"} height={height || "300"}
            viewBox={ viewBox || `0 0 300 300`} 
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M0 150C0 67.3828 66.7969 0 150 0C232.617 0 300 67.3828 300 150C300 233.203 232.617 300 150 300C66.7969 300 0 233.203 0 150ZM206.836 93.1641C203.32 89.6484 197.461 88.4766 192.773 90.2344L77.9297 139.453C72.0703 141.797 69.1406 148.242 70.3125 154.102C71.4844 159.961 76.7578 164.062 82.6172 164.062H135.938V216.797C135.938 223.242 140.039 228.516 145.898 229.688C147.07 229.688 147.656 229.688 148.242 229.688C153.516 229.688 158.203 226.758 160.547 222.07L209.766 107.227C211.523 102.539 210.352 96.6797 206.836 93.1641Z" 
                fill={color || "#01B561"}
            />
        </svg>
    )
};

export default LocationRegion;