// types & models
import { IATFacilityMapSettingDto } from "@algo/network-manager/models/v3";

// configuration and testing
export const isTesting: boolean = false;

export const D_MAP_CENTER: any = {
    lat: 32.365,
    lng: -86.315
};

export const D_MAP_ZOOM: any = 10;

export const D_MAP_SETTINGS: IATFacilityMapSettingDto = {
    centerLatitude: D_MAP_CENTER.lat,
    centerLongitude: D_MAP_CENTER.lng,
    zoomLevel: D_MAP_ZOOM
};

// geographic

// 1 degree latitude ~= 60miles
// so 1 / 60 degrees ~= 1 mile
// so 1 / 60 * 10 or 10 / 60 ~= 10 miles
// so 0.17 ~= 10 miles
export const tenMileLat: number = 0.16667;

// colors
export const floating_date_time_bg: string = "rgba(30,30,30, 0.75)";

export const algo_green_text_light: string = "rgba(15, 93, 64, 1)";
export const algo_green_text_dark: string = "rgba(1, 181, 97, 1)";

export const alerts_text: string = "rgba(255,255,255,0.9)";

export const blue_dark: string = "rgba(49, 117, 194, 1)";
export const blue_light: string = "rgba(21, 81, 150, 1)";

export const green_dark: string = "rgba(1, 181, 97, 1)";
export const green_light: string = "rgba(4, 149, 101, 1)";

export const lane_open: string = "rgba(20, 219, 139, 1)";
export const lane_closed: string = "rgba(240, 73, 73, 1)";
export const lane_median: string = "rgba(255, 210, 79, 1)";

export const colors_dark: { [key: string]: string } = {
    "blue_dark": blue_dark,
    "green_dark": green_dark,
    "lane_open": lane_open,
    "lane_closed": lane_closed,
    "alerts_text": alerts_text,
    "algo_green_text": algo_green_text_dark,
    "floating_date_time_bg": floating_date_time_bg
};

export const colors_light: { [key: string]: string } = {
    "green_light": green_light,
    "blue_light": blue_light,
    "lane_open": lane_open,
    "lane_closed": lane_closed,
    "alerts_text": alerts_text,
    "algo_green_text": algo_green_text_light,
    "floating_date_time_bg": floating_date_time_bg
};

export const aleaAlertDark: string = blue_dark;
export const aleaAlertLight: string = blue_light;

export const aldotMessageDark: string = green_dark;
export const aldotMessageLight: string = green_light;

// HERE MAPS
export const darkScheme: string = "normal.night.mobile";
export const lightScheme: string = "normal.day.mobile";

// WEATHER RADAR
export const weather_radar_products: { [key: string]: string } = {
    "future": "north-american-nowcast",
    "past": "north-american-radar",
};

export const weather_radar_number_layers: number = 10;
export const weather_radar_base_url: string = "https://api.velocityweather.com/v1";
export const weather_radar_product: string = weather_radar_products.future;
export const weather_radar_configuration: string = "Standard-Mercator";
export const weather_radar_animation_interval: number = 2000;

// style-values
export const CARD_PADDING: number = 10;
export const BORDER_RADIUS: number = 12;

// sizing
export const iconXXLarge = {width: 80, height: 80};
export const iconXLarge = {width: 64, height: 64};
export const iconLarge = {width: 54, height: 54};
export const iconLocation = {width: 50, height: 50};
export const iconMedium = {width: 48, height: 48};
export const iconSmall = {width: 36, height: 36};
export const iconXSmall = {width: 28, height: 28};
export const pin = {width: 16, height: 16};

export const locationIconSize = iconLarge;
export const SHIELD_WIDTHS: number[] = [54, 54, 64, 80, 82];

// thresholds
// determines whether an alert/message card should be displayed as 1 or 2-high
export const LONG_ALERT_TEXT: number = 150;

// timing
export const dashboardRefreshInterval = 900000; // 15m
export const dataRefreshInterval = 300000;      // 5m
export const listCycleInterval = 15000;         // 15s
//export const listCycleInterval = 5000;         // 15s
export const stateAlertCycleInterval = 20000;   // 20s

// card heights
export const trafficEventMaxHeight: number = 4;
export const travelTimeMaxHeight: number = 4;
export const alertMaxHeight: number = 2;
