// libraries
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html{
    height: 100vh;
    width: 100vw;
  }

  body{
    height: 100vh;
    width: 100vw;
    margin: 0;

    font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto sans", Helvetica Neue, sans-serif;
  }

  #root{
    height: 100vh;
    width: 100vw;
  }
`;