// libraries
import { hoursToAMPM, prependZero } from "@caps-mobile/date-time";
import { nuonoe } from "@caps-mobile/common-lib";
// types & models
import { IATFacilityDto, IATFacilityMapSettingDto } from "@algo/network-manager/models/v3";
import { EHereMapMarkerType, IHereMarkerInit, createDefaultMarker } from "@algo/here-maps";
import { EATFacilityType } from "@algo/network-manager/models/v3";
// constants
import { BORDER_RADIUS, D_MAP_SETTINGS } from "./constants";
// resources
import * as mapPins from "~/resources/algo-traffic-icons/map-pin";

// common styled partial used on card stack elements
export const getRadiusBoxShadow = (color: any) => { 
    return `
        border-radius: ${BORDER_RADIUS}px;
        box-shadow: 0px 0px 50px 0px ${color};
    `
};

// helper logic to return a valid mapSettings object regardless of whether
// the facility exists
export const getMapSettings = (facility?: IATFacilityDto): IATFacilityMapSettingDto => {

    if (!facility) return D_MAP_SETTINGS;

    return (facility.mapSettings && facility.mapSettings[0])
        ? facility.mapSettings[0]
        : D_MAP_SETTINGS;
};

// takes a given minutes number and returns a string of format:
// x hour(s), x minute(s)
export const displayMinutesInHours = (minutes: number) => {

    let h = Math.floor(minutes / 60);
    let m = minutes % 60;

    let retStr: string = ``;

    if (h >= 1){
        retStr += `${h} hour${h > 1 ? "s" : ""}`;
        if (m > 0) retStr += ", ";
    }
    if (m > 0)
        retStr += `${m} minute${m > 1 ? "s" : ""}`;

    return retStr;
};

// build date string of format: MM/DD/YYYY
export const buildDefaultDateString = (): string => {

    let date = new Date();

    let dateText = "";
    dateText += date.getMonth() + 1;
    dateText += "/";
    dateText += date.getDate();
    dateText += "/";
    dateText += date.getFullYear();

    return dateText;
};

// build time string of format: HH:HH AMPM
export const buildDefaultTimeString = (): string => {

    let date = new Date();

    let [ hours, AMPM ] = hoursToAMPM(date.getHours());
    let timeText = ""; 
    timeText += hours;
    timeText += ":";
    timeText += prependZero(date.getMinutes());
    timeText += " ";
    timeText += AMPM;

    return timeText;
};

// given a list of data objects, builds a list of marker objects for the map
export const buildMarkers = (
    dataList: any[], 
    currentIndex: number,
    getCoords: (object: any) => { lat: number, lng: number} | null,
    objectType: string 
) => {

    let markers: any[] = [];

    if (!nuonoe(dataList)) return markers;

    for (let i = 0; i < dataList.length; i++){

        let nextObject: any = dataList[i];
        let nextCoords = getCoords(nextObject)

        let isCurrent = i === currentIndex;
        let type: EHereMapMarkerType = (isCurrent) 
            ? EHereMapMarkerType.Dom
            : EHereMapMarkerType.Default;

        let pinResource: any;

        switch(objectType){
            case "traffic-events":
                pinResource = mapPins.mapEventTypeToPin(dataList[i].type);
                break;
            case "cameras":
                pinResource = mapPins.cameraPin;
                break;
            case "next-facility":
                (nextObject.type === EATFacilityType.WelcomeCenter)
                    ? pinResource = (nextObject.open) 
                        ? mapPins.welcomeCenterPin 
                        : mapPins.welcomeCenterClosedPin
                    : pinResource = (nextObject.open) 
                        ? mapPins.restAreaOrigin 
                        : mapPins.restAreaClosedPin;
                type = EHereMapMarkerType.Default;
                break;
            default:
                pinResource = mapPins.mapEventTypeToPin(dataList[i].type);
        }
            
        let position = nextCoords as H.geo.Point;

        let H: any = (window as any).H;
        if(H){
            if(isCurrent && objectType !== "next-facility"){
                let domMarker: H.map.DomMarker = new H.map.DomMarker(
                    { lat: position.lat, lng: position.lng },
                    {
                        icon: createAnimatedMarkerElement(pinResource)
                    }
                );
                
                markers.push(domMarker);
            }
            else{
                let icon = { 
                    iconSrc: pinResource,
                    size: {w: 28, h: 28}
                };

                const markerInit: IHereMarkerInit = {
                    type,
                    position,
                    options: {icon}
                };

                let marker: H.map.Marker | null = createDefaultMarker(markerInit);
                marker?.setData(markerInit.data);
                        
                markers.push(marker);
            }
        }
    }

    return markers;
};

// given a list of camera data objects, builds a camera marker for the map
export const buildCameraMarker = (
    dataList: any[], 
    currentIndex: number,
    getCoords: (object: any) => { lat: number, lng: number} | null,
) => {

    let markers: any[] = [];

    if (!nuonoe(dataList)) 
        return markers;

    let currentCamera = dataList[currentIndex];

    if (!currentCamera)
        return [];

    //let type = EHereMapMarkerType.Dom;
    let type = EHereMapMarkerType.Default;
    let position = getCoords(currentCamera) as H.geo.Point;;

    let icon = {
        // element: () => 
        //     createAnimatedMarkerElement(
        //         mapPins.cameraPin
        //     )
        
        iconSrc: mapPins.cameraPin,
        size: {w: 40, h: 40}
        
    };

    const markerInit: IHereMarkerInit = {
        type,
        position,
        options: {icon}
    };

    let marker: H.map.Marker | null = createDefaultMarker(markerInit);
    marker?.setData(markerInit.data);
            
    markers.push(marker);

    return markers;
};

// used to filter events list, returning only those events which exist
// within the lat/lng range within the map viewport
export const filterOutOfBoundsObjects = (
    objectsList: any[], 
    bounds: number[],
    getCoords: (object: any) => { lat: number, lng: number} | null
) => {

    let filteredList: any = [];

    for (let i = 0; i < objectsList.length; i++){

        let nextObject = objectsList[i];

        let coords: {lat: number, lng: number} | null = getCoords(nextObject);

        // if coords returned null, there was an issue getting coords, so skip this event
        if (!coords) continue;

        let nextLat = coords.lat;
        let nextLng = coords.lng;

        if (
            nextLng > bounds[0] && 
            nextLat > bounds[1] && 
            nextLng < bounds[2] &&
            nextLat < bounds[3]
            
        ){
            filteredList.push(objectsList[i]);
        }
    }

    return filteredList;

};

// creates a map marker that 'pulses' or 'breathes' to indicate currently active
export const createAnimatedMarkerElement = (
    // determines the marker image
    iconSrc: string,
    // determines the type of animation
    animationString: string = "breathe 2.5s infinite",
    // determines the marker dimensions
    width: string = "42px", height: string = "42px",    
    // determines where the marker image will appear within parent element
    // typically needs to be set to prevent the marker "moving" on map zoom
    anchorTop: string = "-42px", anchorLeft: string = "-21px"
) => {

    const icon = new H.map.DomIcon(`
        <div>
            <style>
                .ripple {
                    width: ${width};
                    height: ${height}; 
                    margin-top: ${anchorTop};
                    margin-left: ${anchorLeft};
                    animation: ${animationString};
                }
            </style>
            <img class="ripple" src="${iconSrc}">
        </div>
    `);

    return icon;
};

export const createAnimatedOriginElement = (
    H: any,
    outerWidth: number = 42, outerHeight: number = 42,
    innerWidth: number = 24, innerHeight: number = 24, 
) => {
        // these 'anchor' values are used to apply margins such that zooming on the map
        // does not cause the marker to "move around" or "shift position" during zoom
        let outerAnchorTop = ( -1 * outerWidth);
        let outerAnchorLeft = (-0.5 * outerHeight);

        let innerAnchorTop = (0.5 * outerHeight) - (0.5 * innerHeight);
        let innerAnchorLeft = (0.5 * outerWidth) - (0.5 * innerWidth);

        const icon = new H.map.DomIcon(`
            <div>
            <style>
                .originRipple {
                    animation: breathe 2.5s infinite;
                    width: ${innerWidth}px;
                    height: ${innerHeight}px;
                    margin-top: ${innerAnchorTop}px;
                    margin-left: ${innerAnchorLeft}px;
                    background-color: #155196;
                    border-radius: 50%;
                }
                .originOuter {
                    width: ${outerWidth}px;
                    height: ${outerHeight}px;
                    margin-top: ${outerAnchorTop}px;
                    margin-left: ${outerAnchorLeft}px;
                    background-color: #fff;
                    border-radius: 50%;
                    box-shadow: 0px 0px 16px 0px rgba(86,133,255,0.76);
                    transformation: matrix(1, 0, 0, 1, 1034, 196);
                    position: absolute;
                    pointer-events: all;
                }
            </style>
            <div class="originOuter">
                <div class="originRipple"></div>
            </div>
        </div>
    `);

    return icon;
}