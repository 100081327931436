// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledAleaAlertStack = styled.div`
    height: auto;
    width: 100%;

    box-sizing: border-box;
`;

export const StyledAldotMessageStack = styled.div`
    height: auto;
    width: 100%;

    box-sizing: border-box;
`;