// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const SnapshotWrapper = styled.div<{url?: string}>`
    height: 100%;
    width: 100%;

    padding: 14px;

    box-sizing: border-box;

    ${flexSmart("space-between center")}
    flex-direction: column;

    ${props => props.url ? `background-image: url(${props.url}?=${Math.random()});` : ""}
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
`;

export const TopRow = styled.div`
    height: auto;
    width: 100%;

    ${flexSmart("flex-start center")}
`;

export const BottomRow = styled.div`
    height: auto;
    width: 100%;

    ${flexSmart("space-between flex-end")}
`;

export const RoutesOverlay = styled.div`
    height: auto;
    width: auto;

    padding: 4px 8px;

    border-radius: 8px;

    color: rgba(255, 255, 255, 0.8);

    background-color: rgba(30, 30, 30, 0.75);
`;

export const Primary = styled.div`
    height: auto;
    width: auto;

    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
`;

export const Cross = styled.div`
    height: auto;
    width: auto;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
`;

export const CameraIcon = styled.img``;

export const TagOverlay = styled.div`
    height: auto;
    width: auto;

    padding: 4px 8px;

    border-radius: 6px;

    color: rgba(255, 255, 255, 0.8);

    background-color: rgba(30, 30, 30, 0.75);
`;

export const Tag = styled.div``;