import _locationPin from "./location-pin.svg";
import _locationRegion from "./location-region.svg";
import _restArea from "./rest-area.svg";
import _welcomeCenter from "./welcome-center.svg";

import _LocationPin from "./LocationPin";
import _LocationRegion from "./LocationRegion";
import _WelcomeCenter from "./WelcomeCenter";
import _RestArea from "./RestArea";

export const LOCATION_ICONS = {
    pin: _locationPin,
    region: _locationRegion,
    restArea: _restArea,
    welcomeCenter: _welcomeCenter
};

export const LOCATION_COMPS = {
    pin: _LocationPin,
    region: _LocationRegion,
    restArea: _RestArea,
    welcomeCenter: _WelcomeCenter
}

export type ILocationProps = {
    width?: number;
    height?: number;
    viewBox?: string;
    color?: string;
};