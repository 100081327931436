// store
import {FilteredDataActionCreators, filteredDataSlice} from "./filtered-data-store";
// interfaces & models
import { IATFacility, IATLocationDto } from "@algo/network-manager/models/v3";
import { 
    FacilityNetworkManager as NetworkManager, IFacilityNetworkManager, 
} from "@algo/network-manager/managers/v3";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../api-endpoint-constants";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).facilities}`;

// create abstract 'FilteredDataActionCreators' class
// this class will take care of creating a slice and actions as well
export class ActionCreator extends FilteredDataActionCreators{

    constructor(facilityId: number, testMode: boolean = false){
        super(
            "next-facilities",
            facilityId,
            new NetworkManager(apiUrl).getNextFacilities, 
            testMode
        );
    };

    // utilizes the here map view bounds data to filter
    // objects which fall outside the geographical area currently in the map view
    filterData = () => {
        
        return (dispatch: any, getState: any) => {
            dispatch(super.filterData(getNextFacilitiesCoords))
        }
    };

}

// returns a coordinates object representing the location of an object
export const getNextFacilitiesCoords = (
    facility: IATFacility
): {lat: number, lng: number} | null => {

    let firstLocation: IATLocationDto = facility.locations[0];

    if (firstLocation)
        return {
            lat: firstLocation.latitude,
            lng: firstLocation.longitude
        };

    return null;

};

export const nextFacilitiesReducer = filteredDataSlice("next-facilities").reducer;