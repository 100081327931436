// libraries
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { arraysAreEqual } from "@caps-mobile/common-lib";
import { setBounds } from "~/store/here-map/dashboard-map";

// logic: watches for change in map bounds object to trigger store filter
// (don't want to show store items that fall outside of map 'view area')
export const useWatchMapBounds = (map: any) => {

    var bounds = map?.getViewModel().getLookAtData().bounds.getBoundingBox();
    var SWlong = bounds?.getLeft();
    var SWlat = bounds?.getBottom();
    var NElong = bounds?.getRight();
    var NElat = bounds?.getTop();

    const dispatch = useDispatch();

    const [curBounds, setCurBounds] = 
        useState([SWlong, SWlat, NElong, NElat] || []);

    useEffect(
        () => {

            // get the current bounds for the map view (lat, lng values at corners)
            var bounds = map?.getViewModel().getLookAtData().bounds.getBoundingBox();
            var SWlong = bounds?.getLeft();
            var SWlat = bounds?.getBottom();
            var NElong = bounds?.getRight();
            var NElat = bounds?.getTop();
            let boundsNow: any = [SWlong, SWlat, NElong, NElat];

            // check for change from previous bounds
            if (!arraysAreEqual(curBounds, boundsNow) && map){
                // update the saved map's bounds value
                dispatch(setBounds(map))
                // remember the current bounds for next comparison
                setCurBounds(boundsNow);
            }

            // trigger on any change to curBounds, or saved store map object
        }, [curBounds, map]
    )
};