// libraries
import * as React from "react";
import { getRadiusBoxShadow } from "~/library";
// store
import { useSelector } from "react-redux";
// hooks & context
import { ThemeContext } from "~/theme";
// types & models
// components
import { CycleView } from "@caps-mobile/cycle-view";
import { useBuildCameraStack } from "./hooks";

export type CameraStackProps = {
    //
};

export const CameraStack: React.FC<CameraStackProps> = (props) => {

    const theme: any = React.useContext(ThemeContext);
    const pageControl = useSelector((state: any) => state["page-control"]);
    const cameraStore = useSelector((state: any) => state["cameras"]);

    const radiusBoxShadow: string = 
        getRadiusBoxShadow(theme.colors["drop-shadow"].rgba);

    let snapshotHeight: number = 2;

    if (pageControl && pageControl.cardHeightMap){
        snapshotHeight = pageControl.cardHeightMap["cameras"];
    }

    const cameraStackList: React.ReactNode[] = useBuildCameraStack(cameraStore.filteredData, snapshotHeight, cameraStore.lastChecksum);

    return (
        <CycleView 
            styleOverwrite={radiusBoxShadow}
            displayList={cameraStackList} 
            displayIndex={cameraStore.currentDisplayIndex} 
        />
    );
};