// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// enums
import { EStackPosition } from "./enums";
import { BORDER_RADIUS, CARD_PADDING } from "~/constants";

export type IStyledStackableCardProps = {
    stackPosition?: EStackPosition;
    cardHeight?: number;
    cardHeightFactor?: string;
    cardPadding?: string;
    styleOverwrite?: string;
} & React.HTMLAttributes<HTMLElement>;

export const StyledStackableCard = styled.div<IStyledStackableCardProps>`
    ${props => `
    
        width: 100%;
        overflow: hidden;
        height: calc(${props.cardHeight ? `${props.cardHeight}` : "1"} * (100vh * ${props.cardHeightFactor ? props.cardHeightFactor : ".115"}));

        background-color: ${props.theme.colors["bg-primary"].rgba};

        box-sizing: border-box;

        padding: ${props.cardPadding ? props.cardPadding : `${CARD_PADDING}px`};

        ${flexSmart("space-between center")}
       
        ${(
            props.stackPosition === EStackPosition.Solo || 
            props.stackPosition === null || 
            props.stackPosition === undefined
        ) 
            ? `
                border-radius: ${BORDER_RADIUS}px;
            ` 
            : (props.stackPosition === EStackPosition.Top) 
                    ? `
                        border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;
                    `    
                    : (props.stackPosition === EStackPosition.Bottom)
                        ? `
                            border-radius: 0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
                        `
                        : `
                            border-radius: 0 0 0 0;
                        `
        }

        background-clip: padding-box;

        ${props.styleOverwrite ? props.styleOverwrite : ""}
    `}
`