// libraries
import * as React from "react";
// hooks
import { useCardHeights } from "./hooks/card-heights";
// styles
import * as SC from "./Styled";
// components
import { WeatherStack } from "./weather-stack/WeatherStack";
import { TravelTimeStack } from "./travel-time-stack/TravelTimeStack";
import { TrafficEventStack } from "./traffic-event-stack/TrafficEventStack";
import { LocationStack } from "./location-stack/LocationStack";
import { AlertStack } from "./alert-stack/AlertStack";
import { CameraStack } from "./camera-stack/CameraStack";
import { useSelector } from "react-redux";

export type CardsSectionManagerProps = {
    //
};

export const CardsSectionManager: React.FC<CardsSectionManagerProps> = (props) => {

    const facilityStore: any = useSelector( (state: any) => state.facility);

    // watches store values to determine card height allowances
    useCardHeights();
    
    if (!facilityStore.data) return null;

    return (
        <SC.StyledCardsSectionManager>
            
            {/* Facility Name / Location */}
            <LocationStack />

            {/* Current Weather and Active Weather Alerts */}
            <WeatherStack />

            {/* Alea Alerts and Aldot Messages */}
            <AlertStack />

            {/* Travel Times */}
            <TravelTimeStack />

            {/* TrafficEvents */}
            <TrafficEventStack />

            {/* Camera Snapshot */}
            <CameraStack />
            
        </SC.StyledCardsSectionManager>
    );
}