// libraries 
import { 
    EATDirection, EATPlaceType, IATLaneDirection, 
    IATLocation, IATTrafficEvent 
} from "@algo/network-manager/models/v3";
import * as React from "react";
// styles
import * as SC from "./Styled";
// resources
import { TrafficArrow } from "~/resources/images/trafficArrow";
import { extractIntsFromStr, nuon } from "@caps-mobile/common-lib";
import Sign from "./sign/Sign";
import { ThemeContext } from "~/theme";
// constants

export type IProps = {
    trafficEvent: IATTrafficEvent
};

export const Signage: React.FC<IProps> = (props) => {

    const {
        trafficEvent
    } = props;

    const theme: any = React.useContext(ThemeContext);

    const laneDirection: IATLaneDirection | null = (trafficEvent.laneDirections)
        ? trafficEvent.laneDirections[0]
        : null;

    // we cannot render the direction data if there is none: abort
    if (!laneDirection)
        return null;

    let routeNumbers: number[] = 
        extractIntsFromStr(trafficEvent.startLocation.routeDesignator || "");

    let shieldType: EATPlaceType = trafficEvent.startLocation.routeDesignatorType;

    let directionString: string = getDirectionString(trafficEvent);

    const start: IATLocation = trafficEvent.startLocation;
    const end: IATLocation = trafficEvent.endLocation;

    const hasStart: boolean = nuon(start);
    const hasEnd: boolean = nuon(end);

    return (
        <SC.StyledSignageRow centerOnlyChild={!(hasStart && hasEnd)} >
            
            <Sign 
                routeNumbers={routeNumbers} shieldType={shieldType}
                directionString={directionString} 
                location={determineStartLocation(start, end, laneDirection)}
            />

            {hasStart && hasEnd &&
                <TrafficArrow pathFill={theme.colors["text-primary"].rgba} />
            }

            <Sign 
                routeNumbers={routeNumbers} shieldType={shieldType}
                directionString={directionString} 
                location={determineEndLocation(start, end, laneDirection)}
            />

        </SC.StyledSignageRow>
    );
};

export default Signage;

// determine 'start' milepost ordering based on direction of travel
const determineStartLocation = (
    start: IATLocation, 
    end: IATLocation, 
    laneDirection: IATLaneDirection 
): IATLocation | undefined => {

    // if we have a start location
    if (start){
        switch(laneDirection.direction){
            case EATDirection.West:
            case EATDirection.South:
                return end || start;
            case EATDirection.East:
            case EATDirection.North:
            default:
                return start;
        }
    }

    // if we have no start, but there IS an end,
    // then just use that
    else if (end) return end;

    else return undefined;
};

// determine 'end' milepost ordering based on direction of travel
const determineEndLocation = (
    start: IATLocation, 
    end: IATLocation, 
    laneDirection: IATLaneDirection 
): IATLocation | undefined => {

    // if there was no start, do not bother with end logic,
    // we handled the end case already in start logic
    if (!start) return undefined;
    // if we have an end location
    if (end){
        switch(laneDirection.direction){
            case EATDirection.West:
            case EATDirection.South:
                return start;
            case EATDirection.East:
            case EATDirection.North:
            default:
                return end;
        }
    }
    // if we do not have an end, just do not return one
};

// gets the relevant display string for the given direction of travel
export const getDirectionString = (trafficEvent: IATTrafficEvent): string => {

    let laneDirection: IATLaneDirection | null = 
        trafficEvent.laneDirections
            ? trafficEvent.laneDirections[0]
            : null;
        
    return (
        laneDirection 
            ? `${laneDirection.direction}`
            : "N/A"
    )
};