
import { IATCamera } from "@algo/network-manager/models/v3";
import * as React from "react";
import CardStack from "~/components/views/card-stack/CardStack";
import Snapshot from "./camera/Snapshot";
import * as SC from "./Styled";

export const useBuildCameraStack = (
    filteredData: any[], snapshotHeight: number, lastChecksum: string
) => {

    const [ cameraStack, setCameraStack ] = React.useState<any[]>([]);

    React.useEffect(
        () => {

            let newCameraStackList = filteredData 
                ?   filteredData.map(
                        (camera: IATCamera) => {
                            return (
                                <SC.StyledCameraStack>
                                    <CardStack 
                                        stackHeight={snapshotHeight}
                                        cardHeights={[2]}
                                        cardStyleOverwrite={`padding: 0;`}
                                        cards={[ <Snapshot camera={camera} key={`${camera.id}${lastChecksum}`}/> ]}
                                    />
                                </SC.StyledCameraStack>
                            );
                        }
                    )
                :   [];

                setCameraStack(newCameraStackList);

        }, [filteredData, snapshotHeight, lastChecksum]
    );

    return cameraStack;
}