// libraries
import { createSlice } from "@reduxjs/toolkit";
// interfaces & models
import { 
    WeatherAlertNetworkManager as Networkmanager, 
    IProcessedResponse, ProcessedResponse 
} from "@algo/network-manager/managers/v3";
// constants
import {T_FACILITIES} from "~/test-data";
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../api-endpoint-constants";
import { nuonoe } from "@caps-mobile/common-lib";
import { buildLastResponse } from "../common-abstracts";
declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).weatherAlerts}`;

export type ITrafficEventState = {
    data?: any[] | null,
    errorMessage?: string,
    status: number,
    noContent: boolean,
    
    lastData: any[] | null,
    lastErrorMessage: string,
    lastChecksum: string,
    lastCount: number,

    loading: boolean,
    currentDisplayIndex: number,
};

const initialState: ITrafficEventState = {
    data: null,
    errorMessage: "",
    status: 0,
    noContent:false,

    lastData: null,
    lastErrorMessage: "",
    lastChecksum: "",
    lastCount: 0,

    loading: false,
    currentDisplayIndex: 0,
};

// create slice
export const weatherAlertSlice = 
    createSlice(
        {
            name: `weather-alerts`,
            initialState,
            reducers: {
                begin: (state) =>  {
                    state.loading = true
                },
                success: (state, action) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastData = action.payload.data;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount;

                    state.loading = false;  
                },
                failure: (state, action) => {
                    state.data = [];
                    state.errorMessage = action.payload.errorMessage;

                    state.lastChecksum = "";
                    state.lastCount = 0;

                    state.loading = false;
                },
                incrementDisplayIndex: (state) => {
                    let curIndex = state.currentDisplayIndex;

                    // either increment the list or reset the value depending on current index
                    state.currentDisplayIndex = (state.data && ((state.data.length - 1) > curIndex))
                        ? state.currentDisplayIndex + 1
                        : 0;
                },
                resetDisplayIndex: (state) => {
                    state.currentDisplayIndex = 0;
                }
            }
        }
    )

const {
    begin, 
    success,
    failure,
    incrementDisplayIndex,
    resetDisplayIndex
} = weatherAlertSlice.actions;

export const incrementIndex = (
    dispatch: any,
    getState: any
) => {
    dispatch(incrementDisplayIndex());
}

export const resetIndex = (
    dispatch: any,
    getState: any
) => {
    dispatch(resetDisplayIndex());
}

export const getActive = (
    zones: string[]
) => {
    return (
        dispatch: any, 
        getState: any
    ) => {
        let manager = new Networkmanager(apiUrl);

        dispatch(begin);

        // if zones is uon or empty, don't bother getting alerts
        if (!zones || zones.length === 0) return;

        manager.getActive(
            buildLastResponse(getState()["weather-alerts"]),
            {zone: zones}, 
            false
        )
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message}))
                    else 
                        dispatch(success(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message}))
            )
    }
}

export const getActive_test = (
    id?: number
) => {
    return (
        dispatch: any, 
        getState: any
    ) => {

        dispatch(begin);

        let testData = T_FACILITIES[id as keyof typeof T_FACILITIES];
        let activeAlerts = testData["weather-alerts"];

        dispatch(success({data: activeAlerts}));
    }
};

export const weatherAlertReducer = weatherAlertSlice.reducer;