// libraries
import * as React from "react";
import { calculateIntensity, getHighContrastText } from "@caps-mobile/styled-lib";
// styles
import * as SC from "./Styled";
// types & models
import { ICWAlert } from "@algo/network-manager/models/v3/cw";

export type IProps = {
    alert: ICWAlert;
};

export const ActiveAlert: React.FC<IProps> = (props) => {

    const { alert } = props;

    const { red, green, blue } = 
        alert?.fillColor || {red: 0, green: 0, blue: 0};

    let intensity: number = 
        calculateIntensity({red, green, blue});

    let contrastTextColor: string = 
        getHighContrastText({intensity});

    let color: string = `#${alert.fillColor.hex}`;
    
    return (
        <SC.StyledAlertBar 
            color={contrastTextColor} 
            bgColor={color}
        >
            {alert.name}
        </SC.StyledAlertBar>
    )
};

export default ActiveAlert;