// libraries
import * as React from "react";
import { getMapSettings } from "~/library";
// hooks & context
import { useMarkerList } from "./hooks/marker-list";
import { useWatchMapBounds } from "./hooks/map-bounds";
import { EThemeOptions, ThemeContext } from "~/theme";
import { useSelector } from 'react-redux';
// styles
import * as SC from "./Styled";
// components
import { HereMap, IHereMapConfig } from "@algo/here-maps";
import {
     ATFacility, IATFacility, 
     IATFacilityMapSettingDto,IATLocationDto
} from "@algo/network-manager/models/v3";
import { IUnitSliceState } from "../../../../store/common-abstracts";
// constants
import { 
    BORDER_RADIUS, darkScheme, lightScheme
} from "~/constants";
import { useWeatherRadar } from "./hooks/weather-radar/useWeatherRadar";
import WeatherRadarLegend from "./WeatherRadarLegend";
import WeatherRadarContext from "~/contexts/WeatherRadarContext";

/***************************************************************
    HELPERS
***************************************************************/
const featureClick = (feature: any) => {
    console.log("Feature clicked: ", feature);
}

export type IProps = {
    //
};

export const HereMapSectionManager: React.FC<IProps> = () => {

    const theme: any = React.useContext(ThemeContext);

    const [mapAndPlatform, setMapAndPlatform] = React.useState<[H.Map | undefined, H.service.Platform | undefined]>([undefined, undefined]);

    /***************************************************************
        STORE
    ***************************************************************/
    const facilityState: IUnitSliceState = 
        useSelector( (state: any) => state.facility);

    const weatherLayer: any =
        useSelector( (state: any) => state["weather-layer"]);

    /***************************************************************
        CALCULATED VALUES & BREVITY NAMES
    ***************************************************************/
    const facility: IATFacility | undefined = 
        (facilityState.data) 
            ? new ATFacility(facilityState.data)
            : undefined;

    const mapSettings: IATFacilityMapSettingDto = getMapSettings(facility);
    const mapCenter: any = 
        {lat: mapSettings.centerLatitude, lng: mapSettings.centerLongitude};

    const pointsOfEntry: IATLocationDto[] = facility?.locations || [];

    const mapViewOrigin: any = 
        (pointsOfEntry && pointsOfEntry.length > 0)
            ? {
                lat: pointsOfEntry[0].latitude,
                lng: pointsOfEntry[0].longitude
            }
            : {lat: mapCenter.lat, lng: mapCenter.lng}

    const features: any = weatherLayer.data?.features || [];

    const mapConfig: IHereMapConfig = {
        center: mapCenter,
        zoom: mapSettings.zoomLevel,
        theme: (theme.mode === EThemeOptions.Dark) ? darkScheme : lightScheme,
        showSpeeds: true
    };

    useMarkerList(mapViewOrigin, mapAndPlatform[0]);

    // watch for change in HereMap map object
    useWatchMapBounds(mapAndPlatform[0]);

    // weather radar
    useWeatherRadar(mapAndPlatform[0]);
    const weatherRadarContext: any = React.useContext(WeatherRadarContext);

    /***************************************************************
        PRIMARY RENDER
    ***************************************************************/

    return (
        <SC.StyledHereMapSectionManager>
            {
                facility && <HereMap 
                    rounded={`${BORDER_RADIUS}px`} mapConfig={mapConfig}
                    // array of arrays of markers to render to map
                    
                    // array of geoJson features to render to map
                    features={features}
                    // triggered when a geoJson feature is clicked on the map
                    featureCallback={featureClick}
                    // prevent user interaction with map
                    disableBehavior={true}
                    // triggered when map is initialized to provide external handle
                    storeMapAndPlatform={ 
                        (map: H.Map, platform: H.service.Platform) => { setMapAndPlatform([map, platform]); } 
                    }
                    // used to toggle a polygon representing area outside of which data is filtered
                    showBounds={false}
                    // used to show traffic layer
                    showTrafficLayer={true}
                />
            }
            {weatherRadarContext.start && weatherRadarContext.end && weatherRadarContext.current &&
                <SC.StyledOverlaySection 
                    bottom={36} 
                    styleOverwrite={`width: auto; margin-left: 20px`}>
                    <WeatherRadarLegend />
                </SC.StyledOverlaySection>
            }
        </SC.StyledHereMapSectionManager>
    )
}