/**************************************
    VERSION NUMBERS
**************************************/
export const API_V1 = "v1.0";
export const API_V2 = "v2.0";
export const API_V3 = "v3.0";

export const CUR_API_VERSION = API_V3;

/**************************************
    ENDPOINT STRINGS
**************************************/

export const V1_ENDPOINTS = {
    aldotMessages: "AldotMessages",
    aleaAlerts: "AleaAlerts",
    cameras: "Cameras",
    cities: "Cities",
    cloudStreamTargets: "CloudStreamTargets",
    counties: "Counties",
    datasources: "Datasources",
    devices: "Cameras/Devices",
    facilities: "Facilities",
    feedbacks: "Feedbacks",
    forecasts: "Forecasts",
    messageSigns: "MessageSigns",
    organizations: "Organizations",
    places: "Places",
    privileges: "Privileges",
    profiles: "Users",
    roles: "Roles",
    synchronizations: "Synchronizations",
    trafficEvents: "TrafficEvents",
    travelTimes: "TravelTimes",
    users: "Users",
    videoboards: "Videoboards",
    weatherAlerts: "WeatherAlerts",
    weatherCategories: "WeatherCategories",
    weatherLayer: "WeatherAlerts",
    weatherRadar: "WeatherRadar",
    zones: "Zones"
};

export const V2_ENDPOINTS = {
    ...V1_ENDPOINTS,
};

export const V3_ENDPOINTS = {
    ...V2_ENDPOINTS
};

export const CUR_API_ENDPOINTS = (version: string) => {

    switch(version){
        case "v1.0":
            return V1_ENDPOINTS;
        case "v2.0":
            return V2_ENDPOINTS;
        case "v3.0":
            return V3_ENDPOINTS;
        default: 
            return V2_ENDPOINTS;
    }
}