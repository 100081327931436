// libraries
import * as React from "react";
// hooks & context
import { useSelector } from "react-redux";
import { ThemeContext } from "~/theme";
// types & models
import { IATTrafficEvent } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import { CardStack } from "../../../../views/card-stack/CardStack";
import { CycleView } from "@caps-mobile/cycle-view";
import { TrafficEvent } from "./traffic-event/TrafficEvent";
// constants
import { getRadiusBoxShadow } from "~/library";

export type TrafficEventStackProps = {
    //
};

export const TrafficEventStack: React.FC<TrafficEventStackProps> = (props) => {

    const theme: any = React.useContext(ThemeContext);
    const trafficEventStore = useSelector((state: any) => state["traffic-events"]);
    const pageControl = useSelector( (state: any) => state["page-control"]);

    const radiusBoxShadow: string = 
        getRadiusBoxShadow(theme.colors["drop-shadow"].rgba);
    
    // map traffic event data to create traffic event stacks
    let trafficEventHeight: number = 3;
    
    if (pageControl && pageControl.cardHeightMap){
        trafficEventHeight = pageControl.cardHeightMap["traffic-events"];
    }

    let trafficEventStackList = 
        ( trafficEventStore.filteredData )
            ?   trafficEventStore.filteredData.map(
                    (event: IATTrafficEvent) => {
                        return (
                            <SC.StyledTrafficEventStack>
                                <CardStack 
                                    stackHeight={trafficEventHeight || 3}
                                    cardHeights={[trafficEventHeight || 3]}
                                    cards={[
                                        <TrafficEvent trafficEvent={event} />
                                    ]}
                                />
                            </SC.StyledTrafficEventStack>
                        );
                    }
                )
            :   [];

    return (
        <CycleView 
            styleOverwrite={radiusBoxShadow}
            displayList={trafficEventStackList} 
            displayIndex={trafficEventStore.currentDisplayIndex} 
        />
    )
}

export default TrafficEventStack;