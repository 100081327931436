// libraries
import React from 'react';
// styles
import * as SC from "./Styled";
// enums
import { EStackPosition } from './stackable-card/enums';
// components
import StackableCard from './stackable-card/StackableCard';

export type IProps = {
    stackHeight: number;
    cards?: React.ReactElement[];
    cardHeights?: number[];
    dilineate?: boolean;
    styleOverwrite?: string;
    cardStyleOverwrite?: string;
    cardStyleOverwriteArray?: Array<string | undefined>;
} & React.HTMLAttributes<HTMLElement>;

export const CardStack: React.FC<IProps> = (props) => {

    const {
        stackHeight, cards, cardHeights, dilineate,
        styleOverwrite, cardStyleOverwrite, cardStyleOverwriteArray 
    } = props;

    return (
        <SC.StyledCardStack 
            stackHeight={stackHeight} 
            styleOverwrite={styleOverwrite}
        >
                { cards && cards.map(
                        (card, index: number) => {
                            if (index < stackHeight) {

                                let stackPosition: EStackPosition = 
                                    determineStackPosition(cards, stackHeight, index);

                                let cardHeight: number = (cardHeights) 
                                    ? cardHeights[index] 
                                    : 1;

                                return (
                                    <React.Fragment key={`${card.key}-${index}}`}>
                                        {
                                            (dilineate && index !== 0)
                                                ? <SC.StyledDilineator />
                                                : null
                                        }
                                        <StackableCard 
                                            key={`${card.key}-${index}}`}
                                            cardHeight={cardHeight}
                                            stackPosition={stackPosition}
                                            styleOverwrite={
                                                cardStyleOverwriteArray 
                                                    ? cardStyleOverwriteArray[index] 
                                                    : cardStyleOverwrite
                                            }
                                        >
                                            {card}
                                        </StackableCard>
                                    
                                    </React.Fragment>
                                )
                            }
                        }
                    )
                }
        </SC.StyledCardStack>
    );
}

export default CardStack;

export const MemoCardStack = React.memo(CardStack);

const determineStackPosition = (
    cards: React.ReactElement[], 
    stackHeight: number, 
    index: number
) => {

    return (cards.length === 1 || stackHeight === 1) 
        ?   EStackPosition.Solo 
        :   index === 0
            ? EStackPosition.Top
            : index === stackHeight - 1
                ? EStackPosition.Bottom
                : EStackPosition.Interior;

};