// libraries
import * as React from "react";
 // interfaces & models
import { EATEventType } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import { EVENT_ICON_COMPS } from "~/resources/algo-traffic-icons/event-types";
// constants
import { iconLarge } from "~/constants";

export type TrafficEventCardProps = {
    eventType: EATEventType;
    title?: string;
} & React.HTMLAttributes<HTMLElement>;

export const Header: React.FC<TrafficEventCardProps> = (props) => {

    const {
        eventType, title = "No Title"
    } = props;

    let IconComp = 
        EVENT_ICON_COMPS[eventType as keyof typeof EVENT_ICON_COMPS];

    return (
        <SC.HeaderWrapper style={props.style}>
            <SC.StyledHeaderIcon>
                <IconComp width={iconLarge.width} height={iconLarge.height}/>
            </SC.StyledHeaderIcon>
            <SC.StyledEventTitle>
                {`${title}`}
            </SC.StyledEventTitle>
        </SC.HeaderWrapper>
    )
}

export default Header;