// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { CardStack } from "../../../../views/card-stack/CardStack";
import { LocationCard } from "./location-cards/LocationCard";
// resources

export type ILocationStackProps = {
    //
};

export const LocationStack: React.FC<ILocationStackProps> = (props) => {

    const {
        //
    } = props;

    return (
        <SC.StyledLocationStack>
            <CardStack 
                stackHeight={1}
                cards={[ <LocationCard /> ]}
            />
        </SC.StyledLocationStack>
    )
}