import { EATEventType } from "@algo/network-manager/models/v3";
import { ISVGProps } from "~/resources/images/graphics/interfaces";

// raw svgs
import _crash from "./Crash.svg";
import _incident from "./Incident.svg";
import _regionalEvent from "./RegionalEvent.svg";
import _roadCondition from "./RoadCondition.svg";
import _roadWork from "./RoadWork.svg";

// component-wrapped svgs
import _Crash from "./Crash";
import _Incident from "./Incident";
import _RegionalEvent from "./RegionalEvent";
import _RoadCondition from "./RoadCondition";
import _RoadWork from "./RoadWork";

export const EVENT_TYPES: any = {
    [EATEventType.Crash]: _crash,
    [EATEventType.Incident]: _incident,
    [EATEventType.RegionalEvent]: _regionalEvent,
    [EATEventType.RoadCondition]: _roadCondition,
    [EATEventType.Roadwork]: _roadWork
};

export const EVENT_ICON_COMPS: {[key: string]: React.FC<ISVGProps>} = {
    [EATEventType.Crash]: _Crash,
    [EATEventType.Incident]: _Incident,
    [EATEventType.RegionalEvent]: _RegionalEvent,
    [EATEventType.RoadCondition]: _RoadCondition,
    [EATEventType.Roadwork]: _RoadWork
};

export const mapEventTypeToSvg = (eventType: EATEventType) => {

    switch(eventType){
        case EATEventType.Roadwork:
            return _roadWork;
        case EATEventType.Crash:
            return _crash;
        case EATEventType.Incident:
            return _incident;
        case EATEventType.RegionalEvent:
            return _regionalEvent;
        case EATEventType.RoadCondition:
            return _roadCondition;
        case EATEventType.Unknown:
            return _incident;
        default:
            return _incident;
    }

}