// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS } from "~/constants";

export const StyledLaneDirectionWrapper = styled.div`
    
    height: 100%;
    ${flexSmart("space-between center")}
    flex-direction: column;

    box-sizing: border-box;
`;

export const StyledLaneGraphicSection = styled.div`
    width: auto;
    height: 80px;

    @media(max-height: 1000px){
        height: 75px;
    }

    padding: 10px 10px;

    box-sizing: border-box;

    ${flexSmart("center center")}

    background-color: #282828;

    border: medium solid #fff;
    border-radius: ${BORDER_RADIUS}px;

    @media(max-height: 915px){
        display: none;
    }
`;