// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";


export const TrafficEventWrapper = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("space-between center")}
    flex-direction: column;
`;

export const LanesRow = styled.div`
    ${flexSmart("center center")}
`;