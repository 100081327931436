// libraries
import * as React from "react";
import { useSelector } from "react-redux";
import { useActiveWeatherAlerts, useCurrentWeather } from "./hooks/weather-data-hooks";
// styles
import * as SC from "./Styled";
// components
import { CardStack } from "../../../../views/card-stack/CardStack";
import { WeatherConditions } from "./weather-cards/WeatherConditions";
import { Lens } from "@caps-mobile/lens";

export type WeatherStackProps = {
    //
};

export const WeatherStack: React.FC<WeatherStackProps> = (props) => {

    /***************************************************************
        STATE: store
    ***************************************************************/

    // the current forecast for the kiosk's area of concern
    const currentForecast = 
        useSelector( (state: any) => state["current-forecast"]);

    // track height allowance
    const pageControl = 
        useSelector( (state: any) => state["page-control"]);

    // get the current weather
    useCurrentWeather();
    // get the active weather alerts
    useActiveWeatherAlerts();

    /***************************************************************
        CALCULATED VALUES & BREVITY NAMES
    ***************************************************************/
    const cardList = [];
    const isExtended: boolean =
        pageControl.cardHeightMap["weather-condition"] > 1;

    cardList.push(
        <Lens 
            store={currentForecast} storeName={"Weather"}
            noContentView={
                <WeatherConditions
                    currentConditions={currentForecast.data?.currentConditions}
                    isExtended={isExtended}
                />
            }
            successView={
                <WeatherConditions
                    currentConditions={currentForecast.data?.currentConditions}
                    isExtended={isExtended}
                />
            }
        />
    );

    /***************************************************************
        PRIMARY RENDER
    ***************************************************************/
    return (
        <SC.StyledWeatherStack>
            <CardStack
                stackHeight={pageControl.cardHeightMap["weather"]}
                cardHeights={pageControl.cardHeightMap["weather"]}
                cards={cardList}
            />
        </SC.StyledWeatherStack>
    )
}

export default WeatherStack;