import { TEST_MAP as _testData12 } from "./facility-12";
import { TEST_MAP as _testData13 } from "./facility-13";
import { TEST_MAP as _testData14 } from "./facility-14";
import { TEST_MAP as _testData15 } from "./facility-15";
import { TEST_MAP as _testData16 } from "./facility-16";

export const T_FACILITIES = {
    12: _testData12,
    13: _testData13,
    14: _testData14,
    15: _testData15,
    16: _testData16
}