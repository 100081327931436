// libraries
import * as React from "react";
// types & models
import { IATCamera } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// resources
import noPreviewImg from "~/resources/images/no-camera-preview-url-provided.png";
import { cameraPin } from "~/resources/algo-traffic-icons/map-pin/index";


export type IProps = {
    camera: IATCamera;
};

export const Snapshot: React.FC<IProps> = (props) => {

    let { camera } = props;

    const imageUrl = camera.imageUrl || noPreviewImg;

    const {
        displayRouteDesignator = "Primary Route",
        displayCrossStreet = "Cross Street",
        city, county
    } = camera.location;

    // displays city, unless empty, then county unless empty, then "City"
    const cityTag: string = city ? city : county ? county : "City";

    return (
        <SC.SnapshotWrapper url={imageUrl}>
            
            <SC.TopRow>

                <SC.RoutesOverlay>

                    <SC.Primary>{displayRouteDesignator}</SC.Primary>

                    <SC.Cross>{displayCrossStreet}</SC.Cross>

                </SC.RoutesOverlay>

            </SC.TopRow>

            <SC.BottomRow>

                <SC.CameraIcon src={cameraPin} width={40} height={40} />

                <SC.TagOverlay>

                    <SC.Tag>{cityTag}</SC.Tag>

                </SC.TagOverlay>

            </SC.BottomRow>

        </SC.SnapshotWrapper>
    )
};

export default Snapshot;