// libraries
import { 
    FacilityNetworkManager as NetworkManager,
} from "@algo/network-manager/managers/v3";
// types & models
import { 
    FilteredDataActionCreators, filteredDataSlice
} from "./filtered-data-store";
import { IATCamera } from "@algo/network-manager/models/v3";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../api-endpoint-constants";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).facilities}`;

// create abstract 'FilteredDataActionCreators' class
// this class will take care of creating a slice and actions as well
export class ActionCreator extends FilteredDataActionCreators{

    constructor(facilityId: number, testMode: boolean = false){
        super("cameras", facilityId, new NetworkManager(apiUrl).getCameras, testMode);
    };

    // utilizes the here map view bounds data to filter
    // objects which fall outside the geographical area currently in the map view
    filterData = () => {
        
        return (dispatch: any, getState: any) => {
            dispatch(super.filterData(getCameraCoords))
        }
    }

}

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of camera object for geo-filtering
export const getCameraCoords = (
    camera: IATCamera
): {lat: number, lng: number} | null => {

    let lat: number = camera.location.latitude;
    let lng: number = camera.location.longitude;

    if (lat && lng) 
        return {lat, lng}

    else 
        return null;

};

export const cameraReducer = filteredDataSlice("cameras").reducer;