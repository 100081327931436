// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS, CARD_PADDING } from "~/constants";

export const StyledSignageRow = styled.div<{centerOnlyChild: boolean}>`
    height: 100%;
    width: 100%;

    padding-top: 8px;
    padding-bottom: 4px;

    box-sizing: border-box;

    display: flex;
    justify-content: ${ props => props.centerOnlyChild ? "center" : "space-between"};
    align-items: center;
`;