// libraries
// interfaces & models
import { filteredDataSlice} from "../../store/algo-api/filtered-data-store";
// constants
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../../store/api-endpoint-constants";
import { IATTravelTime } from "@algo/network-manager/models/v3";
import { T_FACILITIES } from "~/test-data";
import {
    FacilityNetworkManager as NetworkManager, 
    IProcessedResponse 
} from "@algo/network-manager/managers/v3";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).facilities}`;

// create slice
// this slice has a common signature, so just use a 'filteredDataSlice'
export const travelTimeSlice = filteredDataSlice("travel-times");

// get brevity handles to the actions defined in 'filteredDataSlice'
const {
    begin, 
    success,
    failure,
    filterDataAction,
    incrementDisplayIndex,
    resetDisplayIndex
} = travelTimeSlice.actions;

// some logical pre-check performed before incrementing display index
// this logic may be unique for any given store type, so isn't abstracted
export const incrementIndex = (
    dispatch: any,
    getState: any
) => {
    // determine how many travel times can be displayed at once
    let allowance: number = getState()["page-control"].cardHeightMap["travel-times"];
    // determine the total number of travel times currently in store
    let count: number = getState()["travel-times"]?.data.length;

    // if count is less than allowance, we do not need to cycle at all, so abort logic.
    if (count <= allowance) return;
    // otherwise, increment the index by the normal, abstract logic
    else dispatch(incrementDisplayIndex());
}

export const resetIndex = (
    dispatch: any,
    getState: any
) => {
    dispatch(resetDisplayIndex());
}

export const getTravelTimes_api = (
    facilityId: number
) => {
    return (
        dispatch: any, 
        getState: any
    ) => {
        let manager = new NetworkManager(apiUrl);

        dispatch(begin());

        manager.getTravelTimes(
            getState()["travel-times"].lastResponse, 
            {id: facilityId}, 
            false
        )
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure(response.error))
                    else 
                        dispatch(success(response))
                }
            ).catch(
                (error: Error) => dispatch(failure(error))
            )
    }
}

export const getTravelTimes_test = (
    id: number = 12
) => {
    return (
        dispatch: any, 
        getState: any
    ) => {

        dispatch(begin());

        let travelTimes = 
            (T_FACILITIES[id as keyof typeof T_FACILITIES] as any)["travel-times"];

        dispatch(success({data: travelTimes}))
    }
};

export const getTravelTimes = (id: number = 12, test?: boolean) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (test)
            return dispatch(getTravelTimes_test(id));
        else 
            return dispatch(getTravelTimes_api(id));
    }
};

export const travelTimeReducer = travelTimeSlice.reducer;