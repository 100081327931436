// libraries
import React from 'react';
// hooks
import { useParams } from 'react-router-dom';
import { useFacilityData, useFacilityErrorRetry } from './hooks/data-fetching';
// store
import { useSelector } from 'react-redux';
// styles
import * as SC from "./Styled";
// components
import { Loading } from '@caps-mobile/loading';
import { FaCircleNotch } from 'react-icons/fa';
import { MdOutlineSyncProblem } from "react-icons/md";
import { GiCardboardBox } from "react-icons/gi";
import {Lens} from '@caps-mobile/lens';
import DashboardPage from './Dashboard';

type IDashboardLENSProps = {
    //
} & React.HTMLAttributes<HTMLElement>;

export const DashboardLENS: React.FC<IDashboardLENSProps> = (props) => {

    /***************************************************************
        GLOBALS
    ***************************************************************/
    // grab the facility id from URL
    const { id: facilityId } = useParams<{[key: string]: string}>();
    
    /***************************************************************
        STATE: store
    ***************************************************************/
    // data from facilities call
    const facilityStore = 
        useSelector( (state: any) => state.facility);

    /***************************************************************
        EFFECTS
    ***************************************************************/
    // get facility data
    // TODO: move this into a prop for Lens when DashboardLens is called in parent
    useFacilityData(facilityId || "");

    // error retry 
    // TODO: move this into a prop for Lens when DashboardLens is called in parent
    useFacilityErrorRetry(facilityId || "", facilityStore);

    return(
        <Lens 
            store={facilityStore}
            loadingView={
                <SC.StyledLoadingWrapper>
                    <Loading size={96}>
                        <FaCircleNotch color={"green"}/>
                    </Loading>
                </SC.StyledLoadingWrapper>
            }
            errorView={
                <SC.StyledErrorWrapper>
                    <MdOutlineSyncProblem fontSize={96} color={"red"} type={"regular"} />
                    <div>There was an error loading facility resources.</div>
                    <div style={{fontSize: "18px"}}>
                        Automatically retrying in 10 seconds...
                    </div>
                </SC.StyledErrorWrapper>
            }
            noContentView={
                <SC.StyledErrorWrapper>
                    <SC.StyledIconsWrapper>
                        <GiCardboardBox fontSize={96} color={"#ab8055"} type={"regular"} />
                    </SC.StyledIconsWrapper>
                    <div>No data was found for the given resource identifier. </div>
                    <div style={{fontSize: "18px"}}>
                        The expected data may be missing, or temporarily unavailable.
                    </div>
                </SC.StyledErrorWrapper>
            }
            successView={<DashboardPage />}
        />
    )
}

export default DashboardLENS;
