// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// const
import { BORDER_RADIUS } from "~/constants";

export const StyledDashboardPage = styled.div`
    height: 100%;
    width: 100%;

    padding: 16px;

    box-sizing: border-box;

    ${flexSmart("space-between center")}

    background-color: ${props => props.theme.colors["bg-page"].rgba}  
`;

export const StyledCardsSection = styled.div`
    height: 100%;
    width: calc(25%);
    min-width: 464px;

    margin-right: 16px;

    ${flexSmart("center center")}

    color: ${props => props.theme.colors["text-primary"].rgba};

    border-radius: ${BORDER_RADIUS}px;
`;

export const StyledMapSection = styled.div`
    height: 100%;
    width: calc(75% - 16px);

    ${flexSmart("center center")}

    color: ${props => props.theme.colors["text-primary"].rgba};

    border-radius: ${BORDER_RADIUS}px;
    background-color: rgba(15, 93, 64, .35);
`;

export const StyledLoadingWrapper = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("center center")}

    background-color: ${props => props.theme.colors["bg-primary"].rgba};
`;

export const StyledErrorWrapper = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("center center")}
    flex-direction: column;

    color: ${props => props.theme.colors["text-primary"].rgba};
    font-size: 32px;
    background-color: ${props => props.theme.colors["bg-primary"].rgba};
`;

export const StyledIconsWrapper = styled.div`
    height: auto;
    width: auto;

    position: relative;
`;