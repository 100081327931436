// libraries
import { createSlice } from "@reduxjs/toolkit";
// interfaces & models
import { IProcessedResponse, ProcessedResponse } from "@algo/network-manager/managers/v3";
import { nuonoe } from "@caps-mobile/common-lib";

export type IUnitSliceState = {
    data: any | null,
    errorMessage: string,
    noContent: boolean;
    status: number;
    
    lastData: any | null,
    lastErrorMessage: string,
    lastChecksum: string,
    lastCount: number,
    lastStatus: number,

    loading: boolean;
    currentDisplayIndex: number;
}

// handles getting a list of all data objects of given type
export const defaultListSlice = (name: string) => {
    return createSlice(
        {
            name: `${name}List`,
            initialState: {
                data: [],
                errorMessage: null,
                noContent: false,
                status: 0,
                lastData: [],
                lastErrorMessage: "",
                lastChecksum: "",
                lastCount: 0,
                lastStatus: 0,
                loading: false,
                currentDisplayIndex: 0
            },
            reducers: {
                begin: (state) =>  {
                    state.loading = true
                },
                success: (state, action) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastData = action.payload.data;

                    state.loading = false;  
                },
                failure: (state, action) => {
                    state.data = [];
                    state.errorMessage = action.payload.errorMessage;

                    state.lastData = [];
                    state.lastErrorMessage = action.payload.errorMessage;

                    state.loading = false;
                },
                incrementDisplayIndex: (state) => {
                    let curIndex = state.currentDisplayIndex;

                    // either increment the list or reset the value depending on current index
                    state.currentDisplayIndex = (state.data && ((state.data.length - 1) > curIndex))
                        ? state.currentDisplayIndex + 1
                        : 0;
                },
                resetDisplayIndex: (state) => {
                    state.currentDisplayIndex = 0;
                }
            }
        }
    )
}


// handles getting a single unit of data of given type by an id value
export const defaultUnitSlice = (name: string) => {
    return createSlice(
        {
            name: `${name}List`,
            initialState: {
                data: null,
                errorMessage: null,
                status: 0,
                noContent: false,
                lastData: null,
                lastErrorMessage: "",
                lastChecksum: "",
                lastCount: 0,
                lastStatus: 0,
                loading: false,
                currentDisplayIndex: 0
            },
            reducers: {
                begin: (state) =>  {
                    state.loading = true
                },
                success: (state, action) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.error;
                    state.status = action.payload.status;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastData = action.payload.data;
                    state.lastStatus = action.payload.lastStatus;

                    state.loading = false;
                },
                failure: (state, action) => {
                    state.data = null;
                    state.errorMessage = action.payload.errorMessage;

                    state.lastData = null;
                    state.lastErrorMessage = action.payload.errorMessage;

                    state.loading = false;
                },
            }
        }
    )
}

/*
    COMMON GET ALL:
        this basic getAll abstract can be used by most data stores
*/
export const getAll = (
    manager: any, 
    begin: any, 
    success: any, 
    failure: any
) => {
    return (
        dispatch: any, 
        getState: any
    ) => {
        
        dispatch(begin());

        manager.getAll(buildLastResponse(getState()), {}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure(response.error.message))
                    else 
                        dispatch(success(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(failure(error.message))
            )
    }
}

/*
    COMMON GET BY ID:
        this basic getById abstract can be used by most data stores
*/
export const getById = (
    manager: any,
    id: number,
    begin: any, 
    success: any, 
    failure: any
) => {
    return (
        dispatch: any, 
        getState: any
    ) => {
        
        dispatch(begin());

        manager.getById(buildLastResponse(getState()), {id}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message}))
                    else 
                        dispatch(success(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message}))
            )
    }
}

export const buildLastResponse = ( state: any ): IProcessedResponse => {

    return new ProcessedResponse(
        {
            data: state.lastData,
            errorMessage: state.lastErrorMessage,
            status: state.lastStatus,
            xChecksum: state.lastChecksum,
            xCount: state.lastCount
        }
    )
};