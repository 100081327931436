// libraries
import { createSlice } from "@reduxjs/toolkit";
// constants

type IInitialState = {
    displayIndex: number;
    cardHeightAllowance: number;
    cardHeightMap: {[key: string]: number};
};

const initialState: IInitialState = {
    displayIndex: 0,
    cardHeightAllowance: 8,
    cardHeightMap: {
        "location": 1,                  // always exists if page has loaded
        "weather": 1,                   // always given at least 1 height if page has loaded
        "weather-alerts": 0,
        "alea-aldot-alert": 0,
        "travel-times":  0,
        "traffic-events": 0,
        "cameras": 0
    }
};

// create list slice
export const pageControlSlice = createSlice(
    {
        name: `page-control`,
        initialState: initialState,
        reducers: {
            setPageControlAction: (state: IInitialState, action: any) => {
                state.cardHeightMap = action.payload.cardHeightMap;
            },
        }
    }
);

// actions
export const { 
    setPageControlAction,
} = pageControlSlice.actions;

// action creators
export const setPageControl = (
    newState: IInitialState
) => {

    return (
        dispatch: any,
        state: any
    ) => {
        dispatch(setPageControlAction({...state, ...newState}));
    }
}

export const pageControlReducer = pageControlSlice.reducer;