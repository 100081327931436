// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// constant
import { BORDER_RADIUS } from "~/constants";

export const StyledCardsSectionManager = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("space-between center")}
    flex-direction: column;

    border-radius: ${BORDER_RADIUS}px;
`;

export type IStyledSpacer = {
    width?: string;
    height?: string;
    styleOverwrite?: string;
}

export const StyledSpacer = styled.div<IStyledSpacer>`
    ${props => `
        width: ${props.width || "auto"};
        height: ${props.height || "auto"};

        ${props.styleOverwrite ? props.styleOverwrite : ""}
    `}
`;