// libraries
import * as React from "react";
import { nuonoe } from "@caps-mobile/common-lib";
// hooks & context
import { ThemeContext } from "~/theme";
// types & models
import { IATAldotMessage, IATAleaAlert } from "@algo/network-manager/models/v3";
// components
import { AutoScroller, EScrollOrientation } from "@caps-mobile/auto-scroller";
// styles
import * as SC from "./Styled";
import { styledScrollbar } from "./Styled";
// constants
import { stateAlertCycleInterval } from "~/constants";

export type IAlertCardProps = {
    alert?: IATAleaAlert | IATAldotMessage;
};

export const AlertCard: React.FC<IAlertCardProps> = (props) => {

    const { alert } = props;
    const theme: any = React.useContext(ThemeContext);

    if (!alert)
        return null;
    
    const initialDelay = 4;

    const hasImages: boolean = nuonoe((alert as any).images);

    const autoScrollStyle: string = `
        background-color: inherit; 
        width: 100%; height: 100%; 
        ${styledScrollbar(theme)}
    `;

    return (
        <SC.AlertCard> 

            <SC.AlertTitle> 
                {alert.title}
            </SC.AlertTitle>

            <AutoScroller styleOverwrite={autoScrollStyle}
                orientation={EScrollOrientation.vertical} 
                cycleDuration={stateAlertCycleInterval}
                initialDelay={initialDelay * 1000} finalDelay={initialDelay * 1000}
            >

                <SC.AlertContentWrapper>

                    <SC.AlertText>
                        {(alert as any).text || (alert as any).body}
                    </SC.AlertText>
                    {
                        hasImages && 
                        <SC.AlertImgsWrapper>
                            {
                                (alert as any).images.map(
                                    (image: {url: string}, index: number) => {
                                        return (
                                            <SC.AlertImg 
                                                key={image.url+index} 
                                                src={image.url} />
                                        )
                                    }
                                )
                            }
                        </SC.AlertImgsWrapper>
                    }

                </SC.AlertContentWrapper>

            </AutoScroller>

        </SC.AlertCard>
    )
};

export default AlertCard;