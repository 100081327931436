// store
import {
    FilteredDataActionCreators, filteredDataSlice
} from "./filtered-data-store";
// interfaces & models
import {
    FacilityNetworkManager as NetworkManager, 
} from "@algo/network-manager/managers/v3";
// constants
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../api-endpoint-constants";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).facilities}`;

// create abstract 'FilteredDataActionCreators' class
// this class will take care of creating a slice and actions as well
export class ActionCreator extends FilteredDataActionCreators{

    constructor(facilityId: number, testMode: boolean = false){
        
        super(
            "travel-times", facilityId,
            new NetworkManager(apiUrl).getTravelTimes, 
            testMode
        );
    };

    filterData = (): any => {
        return (
            dispatch: any,
            getState: any
        ) => {
            dispatch(this.actions.filterDataAction({
                filteredData: getState()[this.sliceName].data
            }));
        }
    }

};

export const travelTimesReducer = filteredDataSlice("travel-times").reducer;