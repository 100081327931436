// models & interfaces
import { 
    ECWWeatherConditionIcon as Type 
} from "@algo/network-manager/models/v3/cw";
// resources
import _blizzard from "./blizzard.png";
import _cloudy from "./cloudy.png";
import _cloudy_night from "./cloudy_night.png";
import _dust from "./dust.png";
import _fair from "./fair.png";
import _fair_night from "./fair_night.png";
import _fog from "./fog.png";
import _haze from "./haze.png";
import _hot from "./hot.png";
import _hot_night from "./hot.png";
import _hurricane from "./hurricane.png";
import _rain from "./rain.png";
import _rain_snow from "./rain_snow.png";
import _rain_showers from "./rain_showers.png";
import _rain_showers_night from "./rain_showers_night.png";
import _overcast from "./overcast.png";
import _snow from "./snow.png";
import _tornado from "./tornado.png";
import _thunderstorm from "./thunderstorm.png";
import _thunderstorm_night from "./thunderstorm_night.png";
import _windy from "./windy.png";
import _windy_night from "./windy_night.png";

// used when no match exists or for 204 no data scenarios
import _unknown from "./unknown.png";

export const weatherIcons = {
    blizzard: _blizzard,
    cloudy: _cloudy,
    cloudy_night: _cloudy_night,
    dust: _dust,
    fair: _fair,
    fair_night: _fair_night,
    fog: _fog,
    haze: _haze,
    hot: _hot,
    hot_night: _hot_night,
    hurricane: _hurricane,
    rain: _rain,
    rain_snow: _rain_snow,
    rain_showers: _rain_showers,
    rain_showers_night: _rain_showers_night,
    overcast: _overcast,
    snow: _snow,
    tornado: _tornado,
    thunderstorm: _thunderstorm,
    thunderstorm_night: _thunderstorm_night,
    windy: _windy,
    windy_night: _windy_night
}


// map algo api "CWWeatherConditionIcon" enum values to 
// their respective icon resource
export const mapConditionIcon = (
    type: Type, 
    night: boolean = false
) => {

    switch(type){
        case Type.FewClouds:
        case Type.MostlyCloudy:
        case Type.PartlyCloudy:
            return night ? _cloudy_night : _cloudy;
        case Type.Overcast:
            return _overcast;
        case Type.Blizzard:
            return _blizzard;
        case Type.Dust:
            return _dust;
        case Type.Fair:
            return night ? _fair_night : _fair;
        case Type.Fog:
            return _fog;
        case Type.FreezingRain:
        case Type.FreezingRainRain:
            return _rain;
        case Type.LightRain:
        case Type.ShowersInVicinity:
        case Type.RainShower:
            return night ? _rain_showers_night : _rain_showers;
        case Type.FreezingRainSnow:
        case Type.IcePellet:
        case Type.RainIcePellet:
        case Type.RainSnow:
            return _rain_snow;
        case Type.Snow:
            return _snow;
        case Type.FunnelCloud:
        case Type.Tornado:
            return _tornado;
        case Type.Haze:
        case Type.Smoke:
            return _haze;
        case Type.Hot:
        case Type.Fair:
        case Type.FewClouds:
            return night ? _hot_night : _hot;
        case Type.Hurricane:
        case Type.TropicalStorm:
            return _hurricane;
        case Type.Thunderstorm:
        case Type.ThunderstormsInVicinity:
            return night ? _thunderstorm_night : _thunderstorm;
        case Type.Windy:
            return night ? _windy_night : _windy;
        default:
            return _unknown;
    };
};