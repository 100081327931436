// libraries
import styled from "styled-components";
import { ellipsis, flexSmart } from "@caps-mobile/styled-lib";
// constants
import { alerts_text, CARD_PADDING } from "~/constants";

export const AlertCard = styled.div`
    height: 100%;
    width: 100%;

    box-sizing: border-box;

    ${flexSmart("flex-start center")}
    flex-direction: column;

    color: ${alerts_text};
`;

export const AlertTitle = styled.div`
    width: 100%;
    min-height: 32px;

    margin-bottom: ${CARD_PADDING}px;

    text-align: center;
    ${props => props.theme.typography["heading-2.5"]}
    ${ellipsis()}
`;

export const AlertContentWrapper = styled.div`
    height: auto;
    width: 98%;

    box-sizing: border-box;
`;

export const AlertText = styled.div`
    width: 100%;
    height: auto;

    ${flexSmart("flex-start flex-start")}

    ${props => props.theme.typography["heading-5"]}
`;

export const AlertImgsWrapper = styled.div`
    marginTop: 8px;

    box-sizing: border-box;
    ${flexSmart("center flex-start")}
`;

export const AlertImg = styled.img`
    width: 100%;
`;

export const styledScrollbar = (theme: any) => {

    return `
        ::-webkit-scrollbar {
            width: 2px;
            padding: 2px;
            margin: 2px;
        }

        ::-webkit-scrollbar-track {
            background: ${theme.colors["bg-primary"]["rgba"]};
            border-radius: 10px;
            width: 2px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${theme.colors["scrollbar-thumb"]["rgba"]};
            
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            width: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: ${theme.colors["scrollbar-thumb-hover"]["rgba"]};
            width: 4px;
        }

        ::-webkit-scrollbar-button{
            height: 0px;
        }

        scrollbar-width: thin;
        scrollbar-color: ${theme.colors["scrollbar-thumb-hover"]["rgba"]} ${theme.colors["scrollbar-bg"]["rgba"]};
    `;
};