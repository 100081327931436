// libraries
import styled from "styled-components";
import { ellipsis, flexSmart } from "@caps-mobile/styled-lib";
import { EATFacilityType } from "@algo/network-manager/models/v3";
// constants
import { CARD_PADDING, locationIconSize } from "~/constants";

export const StyledLocationWrapper = styled.div`
    height: 100%;
    width: calc(100% - ${locationIconSize.width}px - ${CARD_PADDING}px);

    ${flexSmart("space-between center")}

`;

export const StyledIconSection = styled.div`
    height: 100%;
    min-width: ${locationIconSize.width + CARD_PADDING}px;
    
    box-sizing: border-box;

    ${flexSmart("flex-start center")}
`;

export const StyledTextSection = styled.div<{type: EATFacilityType}>`
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    ${flexSmart(`${"space-between"} flex-start`)}
    display: flex;
    justify-content: ${props => 
        (props.type === EATFacilityType.Other)
            ? "center"
            : "space-between"
    };
    align-items: flex-start;
    flex-direction: column;

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const StyledLocationTextPrimary = styled.div<{type: EATFacilityType}>`
    width: 100%;
    ${props => 
        (props.type === EATFacilityType.Other)
            ? props.theme.typography["heading-2"]
            : props.theme.typography["heading-2.5"]    
    }

    ${ellipsis()}
`;

export const StyledLocationTextSecondary = styled.div`
    width: 100%;
    ${props => props.theme.typography["heading-4"]}

    ${ellipsis()}
`;