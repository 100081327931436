// libraries
import { createSlice } from "@reduxjs/toolkit";
// interfaces & models
import { 
    WeatherAlertNetworkManager as Networkmanager, 
    IProcessedResponse 
} from "@algo/network-manager/managers/v3";
// constants
import {T_FACILITIES} from "~/test-data";
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../api-endpoint-constants";
import { ICWAlert } from "@algo/network-manager/models/v3/cw";
import { buildLastResponse } from "../common-abstracts";
declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).weatherLayer}`;

export type ITrafficEventState = {
    data?: ICWAlert[] | null,
    errorMessage?: string,
    status: number,
    noContent: boolean,

    lastData: ICWAlert[] | null,
    lastErrorMessage: string,
    lastStatus: number,
    lastChecksum: string,
    lastCount: number,

    loading: boolean,
};

const initialState: ITrafficEventState = {
    data: null,
    errorMessage: "",
    status: 0,
    noContent:false,

    lastData: null,
    lastErrorMessage: "",
    lastStatus: 0,
    lastChecksum: "",
    lastCount: 0,

    loading: false,
};

// create slice
export const weatherLayerSlice = 
    createSlice(
        {
            name: `weather-layer`,
            initialState,
            reducers: {
                begin: (state) =>  {
                    state.loading = true
                },
                success: (state, action) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !action.payload.data;

                    state.lastData = action.payload.data;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastStatus = action.payload.status;
                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount;

                    state.loading = false;  
                },
                failure: (state, action) => {
                    state.data = null;
                    state.errorMessage = action.payload.errorMessage;
                    
                    state.lastChecksum = "";
                    state.lastCount = 0;

                    state.loading = false;
                }
            }
        }
    )

const {
    begin, 
    success,
    failure,
} = weatherLayerSlice.actions;

export const getActiveLayer = (
    zones: string[]
) => {
    return (
        dispatch: any, 
        getState: any
    ) => {
        let manager = new Networkmanager(apiUrl);

        dispatch(begin);

        //if (!nuonoe(zones)) return;

        manager.getLayer(
            buildLastResponse(getState()["weather-layer"]), 
            {
                zone: zones, 
                includeGeometry: true
            }, 
            false
        )
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message}))
                    else 
                        dispatch(success(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message}))
            )
    }
}

export const getActiveLayer_test = (
    id?: number
) => {
    return (
        dispatch: any, 
        getState: any
    ) => {

        dispatch(begin);

        //let facilityId: number = getState().facility?.id || 12;

        let testData = T_FACILITIES[id as keyof typeof T_FACILITIES];

        let weatherLayer = testData["weather-layer"];

        dispatch(success({data: weatherLayer}));
    }
}

export const weatherLayerReducer = weatherLayerSlice.reducer;