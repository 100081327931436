// libraries
import * as React from "react";
import { nuonoe } from "@caps-mobile/common-lib";
import { getRadiusBoxShadow } from "~/library";
// hooks
import { ThemeContext } from "~/theme";
import { useSelector } from "react-redux";
// styles
import * as SC from "./Styled";
// types & models
import { IATTravelTime } from "@algo/network-manager/models/v3";
// components
import { CardStack } from "../../../../views/card-stack/CardStack";
import { TravelTimeCard } from "./travel-time-cards/TravelTimeCard";
import { CycleView } from "@caps-mobile/cycle-view";

export type TravelTimeStackProps = {
    //
};

export const TravelTimeStack: React.FC<TravelTimeStackProps> = (props) => {

    const theme: any = React.useContext(ThemeContext);
    const travelTimeStore = useSelector((state: any) => state["travel-times"]);
    const pageControl = useSelector( (state: any) => state["page-control"]);

    const radiusBoxShadow: string = 
        getRadiusBoxShadow(theme.colors["drop-shadow"].rgba);
    
    let travelTimeStackList: any = [];

    // create travel time stacks based on pageControl state allowance
    if (nuonoe(travelTimeStore.data) && pageControl){

        let travelTimeAllowance = pageControl.cardHeightMap["travel-times"] || 1;
        let travelTimes = travelTimeStore.data;

        // iterate through each travelTime in data list
        for (let i = 0; i < travelTimes.length; i++){

            let travelTimesForStack: IATTravelTime[] = [];

            // add subsequent travel times to match the total stack height allowance
            for (let j = 0; j < travelTimeAllowance; j++){

                // do not exceed the bounds of travel time data list
                if ((i + j) < travelTimes.length){
                    let travelTime: IATTravelTime = travelTimes[i + j];
                    travelTimesForStack.push(travelTime);
                }
                
            }

            // if we have too few travel times to fill out the alloted space
            // we can cycle back through the available list and pad with duplicates
            if (
                (travelTimesForStack.length > 0) && 
                (travelTimesForStack.length < travelTimeAllowance)
            ){

                let diff: number = travelTimeAllowance - travelTimesForStack.length;

                for (let k = 0; k < diff; k++){
                    // pad some extra cards to maintain height
                    travelTimesForStack.push(travelTimes[k]);
                }
            }

            let cardComps: any[] = [];
            travelTimesForStack?.forEach(
                (travelTime: IATTravelTime) => {
                    if (travelTime) 
                    cardComps.push(
                        <TravelTimeCard key={travelTime.id} travelTime={travelTime} />
                    )
                }
            );

            travelTimesForStack.length > 0 && travelTimeStackList.push(

                <SC.StyledTravelTimeStack>
                    <CardStack
                        stackHeight={travelTimeAllowance}
                        dilineate={true} 
                        cards={cardComps}
                    />
                </SC.StyledTravelTimeStack>
            );
        }

    }

    return (
        <CycleView 
            styleOverwrite={radiusBoxShadow}
            displayList={travelTimeStackList}
            displayIndex={travelTimeStore.currentDisplayIndex}
        />
    )
}

export default TravelTimeStack;