// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import ProgressBar from "./progress-bar/ProgressBar";
import WeatherRadarContext from "../../../../contexts/WeatherRadarContext";
import radar from "~/resources/images/radar.svg";

export type IProps = {
    //
};

export const WeatherRadarLegend: React.FC<IProps> = (props) => {

    const weatherRadarContext: any = React.useContext(WeatherRadarContext);
    
        return (
            <SC.StyledLegend>
                { weatherRadarContext.start && weatherRadarContext.end && weatherRadarContext.current &&
                    <SC.WeatherRadarLegendItems>
                        <SC.WeatherRadarTitle>Radar</SC.WeatherRadarTitle>
                        <SC.WeatherRadarProgressItems>
                            <SC.WeatherRadarTime>{getTime(weatherRadarContext.current)}</SC.WeatherRadarTime>
                            <ProgressBar 
                                color={"rgba(235,235,245, .6)"} 
                                bgColor={"rgba(235,235,245, .3)"}
                                height={"4px"}
                                progress={`${calculateElapsedPercent(weatherRadarContext.start, weatherRadarContext.end, weatherRadarContext.current)}`} 
                            />
                        </SC.WeatherRadarProgressItems>
                    </SC.WeatherRadarLegendItems>
                }
            </SC.StyledLegend>
        );

};

export default WeatherRadarLegend;

const getTime = (date: string) => {
    return new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });;
}

const calculateElapsedPercent = (start: string, end: string, current: string): number => {

    let startDate = new Date(start).valueOf();
    let endDate = new Date(end).valueOf();
    let currentDate = new Date(current).valueOf();

    return Math.round( 
        // time elapsed from start of event until now
        (currentDate - startDate) /
        // divided by the total duration of the event
        (endDate - startDate)
        // by 100 to convert to percent
        * 100
    );
};