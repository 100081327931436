import activeWeatherAlerts from "./test-active-weather-alerts.json";
import aleaAlerts from "./test-alea-alerts.json";
import aldotMessages from "./test-aldot-messages.json";
import currentForecast from "./test-current-forecast.json";
import facility from "./test-facility.json";
import trafficEvents from "./test-traffic-events.json";
import travelTimes from "./test-travel-times.json";
import weatherLayer from "./test-weather-layer.json";
import cameras from "./test-cameras.json";
const nextFacilities: any[] = [];

export const TEST_MAP: any = {
    "weather-alerts": activeWeatherAlerts,
    aleaAlerts,
    aldotMessages,
    "current-forecast": currentForecast,
    facility,
    "traffic-events": trafficEvents,
    "travel-times": travelTimes,
    "weather-layer": weatherLayer,
    "cameras": cameras,
    "next-facilities": nextFacilities
};