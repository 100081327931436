// libraries
import React from 'react';
import styled, {ThemeProvider} from "styled-components";
// hooks
import { useColorSchemePreference } from '@algo/hooks';
// components
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Welcome from './components/pages/welcome/Welcome';
import DashboardLENS from './components/pages/dashboard/DashboardLENS';
// styles
import {EThemeOptions, getTheme, ThemeContext} from "~/theme";
// hooks
import {
  ESiteNavLinks, SiteNavContext
} from './contexts/SiteNavContext';
import { ErrorBoundary } from './components/views/error-boundary/ErrorBoundary';
import WeatherRadarContext, { IWeatherRadarContext, useWeatherRadarContext } from './contexts/WeatherRadarContext';

type IProps = {
  //
};

const App: React.FC<IProps> = () => {

  // toggle value for theme mode light/dark etc.
  const [themeMode, setThemeMode] = 
    React.useState<EThemeOptions>(EThemeOptions.Light);

  // holds the actual JSObject containing theme values
  const [themeObject, setThemeObject] =
    React.useState<any>(getTheme(themeMode));

  // the url for the current site location (for nav)
  const pathName: string = window.location.pathname;

  // note the nav-map index of your current location (for nav)
  const pathIndex: number = 
    ESiteNavLinks[pathName as keyof typeof ESiteNavLinks] || 0;

  // tracks which nav link is currently selected
  const [selectedSiteLink, setSelectedSiteLink] = 
    React.useState<number>(pathIndex);

  // holds information about navigation
  // used for controlling nav-based styles and logic
  const menuContextValue = {
    selectedSiteLink: selectedSiteLink, 
    setSelectedSiteLink: (newVal: number) => {setSelectedSiteLink(newVal)} 
  };

  // triggers when useColorSchemePreference first runs
  const initialThemeSet = () => {

    // if (themeMode !== EThemeOptions.Dark){
    //   setThemeMode(EThemeOptions.Dark);
    //   setThemeObject(getTheme(EThemeOptions.Dark));
    // }

  }

  // triggers when user's prefers-color-scheme value changes
  const listenerCallback = (event: any) => {
    // if (event.matches) {
    //   setThemeMode(EThemeOptions.Dark);
    //   setThemeObject(getTheme(EThemeOptions.Dark));
    // }
    // else {
    //   setThemeMode(EThemeOptions.Light);
    //   setThemeObject(getTheme(EThemeOptions.Light));
    // }
  }

  // watches @media theme mode preference and updates toggles accordingly
  useColorSchemePreference(initialThemeSet, listenerCallback);

  const weatherRadarContextInit: IWeatherRadarContext = useWeatherRadarContext();

  return (
    <ThemeProvider theme={themeObject}>

      <ThemeContext.Provider value={themeObject}>
        
        <StyledApp>
                    
            <ErrorBoundary autoRefresh={true}>

                <BrowserRouter>

                <SiteNavContext.Provider value={menuContextValue}>
                  
                <WeatherRadarContext.Provider value={weatherRadarContextInit}>

                <Routes>
                    <Route path={"/facility/:id"} element={<DashboardLENS />} />
                    <Route path={"*"} element={<Welcome />} />
                </Routes>

                </WeatherRadarContext.Provider>

                </SiteNavContext.Provider>

                </BrowserRouter>

            </ErrorBoundary>

        </StyledApp>

      </ThemeContext.Provider>

    </ThemeProvider>
  );
}

export default App;

export const StyledApp = styled.div`
  ${props => props.theme.globals.globals.app }

  height: 100%;
  width: 100%;

  ${props => `
    background-color: ${props.theme.colors["bg-primary"]["rgba"]};
  `}
`;
