// libraries
import { 
    FacilityNetworkManager as Networkmanager
} from "@algo/network-manager/managers/v3";
import { getAll as _getAll } from "../common-abstracts";
import { getById as _getById } from "../common-abstracts";

// constants
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../api-endpoint-constants";
import {T_FACILITIES}  from "~/test-data";

// test data
import { createSlice } from "@reduxjs/toolkit";
import { nuonoe } from "@caps-mobile/common-lib";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).facilities}`;

type IInitialState = {
    data: any | null,
    errorMessage: string,
    status: number,
    noContent: boolean;

    lastData: any | null,
    lastErrorMessage: string,
    lastStatus: number,
    lastChecksum: string,
    lastCount: number,

    loading: boolean;
};

const initialState: IInitialState = {
    data: null,
    errorMessage: "",
    status: 0,
    noContent: false,

    lastData: null,
    lastErrorMessage: "",
    lastChecksum: "",
    lastCount: 0,
    lastStatus: 0,

    loading: false
};

// create list slice
export const facilitySlice = createSlice(
    {
        name: `facility`,
        initialState,
        reducers: {
            begin: (state: any) => {
                state.loading = true;
            },
            success: (state: any, action: any) => {
                state.data = action.payload.data;
                state.errorMessage = action.payload.errorMessage;
                state.status = action.payload.status;
                state.noContent = !nuonoe(action.payload.data);

                state.lastData = action.payload.data;
                state.lastErrorMessage = action.payload.errorMessage;
                state.lastStatus = action.payload.status;
                state.lastChecksum = action.payload.xChecksum;
                state.lastCount = action.payload.xCount;

                state.loading = false;  
            },
            failure: (state: any, action: any) => {
                state.data = [];
                state.errorMessage = action.payload.errorMessage;
                
                state.lastData = [];
                state.lastErrorMessage = action.payload.errorMessage;
                state.lastChecksum = "";
                state.lastCount = 0;
                state.lastStatus = 0;

                state.loading = false;
            },
        }
    }
);

export const getById = (id: number) => {

    return _getById(
        new Networkmanager(apiUrl),
        id,
        facilitySlice.actions.begin, 
        facilitySlice.actions.success, 
        facilitySlice.actions.failure
    );

    
};

export const getById_test = (id: number, mode?: string) => {

    return (dispatch: any, getState: any) => {

        switch(mode){
            case "error":
                // produce an error
                dispatch(facilitySlice.actions.failure({ 
                    errorMessage: "getById_test error", 
                    data: null } 
                ));
                break;
            case "empty":
                // produce an empty/noContent
                dispatch(facilitySlice.actions.success({ data: null } ));
                break;
            default:
                // produce a normal response using the test object/data
                let testData = T_FACILITIES[id as keyof typeof T_FACILITIES];
                if (!testData){ 
                    dispatch(
                        facilitySlice.actions.failure({
                            errorMessage: "getById_test default error", 
                            data: null
                        })
                    );
                    return;
                }
                else {
                    let facility = (testData as any).facility;
                    dispatch(facilitySlice.actions.success({data: facility}));
                }
        }

    }
    
};

export const facilityReducer = facilitySlice.reducer;