// libraries 
import * as React from "react";
// styles
import * as SC from "./Styled";
// resources
import algoLogoDark from "~/resources/algo-traffic-icons/logo/algo-logo-dark.svg";
import algoLogoLight from "~/resources/algo-traffic-icons/logo/algo-logo.svg";
import { EThemeOptions, ThemeContext } from "~/theme";

export type IProps = {
    //
}

export const Welcome: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    const theme: any = React.useContext(ThemeContext);

    return (
        <SC.StyledWelcome>
            <SC.StyledLogoImg src={theme.mode === EThemeOptions.Dark ? algoLogoDark : algoLogoLight }/> 
            <SC.StyledTextColumn>
                <SC.VisitText>Visit ALGOTraffic.com</SC.VisitText>
                <SC.AdditionalText>To view traffic conditions, cameras, weather updates, and more.</SC.AdditionalText>
            </SC.StyledTextColumn>
        </SC.StyledWelcome>
    );
}

export default Welcome;