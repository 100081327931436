// libraries
import React from 'react';
// hooks
import { useSelector } from 'react-redux';
import { useDashboardData, useRefilter } from './hooks/data-fetching';
import { useInitializeCycle} from "./hooks/data-cycles";
// styles
import * as SC from "./Styled";
// components
import { HereMapSectionManager } from "./here-map-section/HereMapSectionManager";
import { FloatingAlgoLogo } from '../../views/floating-algo-logo/FloatingAlgoLogo';
import { CardsSectionManager } from './cards-section/CardsSectionManager';

type IDashboardProps = {
    //
} & React.HTMLAttributes<HTMLElement>;

export const DashboardPage: React.FC<IDashboardProps> = (props) => {
    
    /***************************************************************
        STORE:
    ***************************************************************/
   
    // data from facilities call
    const facilityStore = 
        useSelector( (state: any) => state.facility);

    /***************************************************************
        EFFECTS
    ***************************************************************/

    let facilityId: number | undefined = 
        (facilityStore.data && facilityStore.data.id)
            ? parseInt(facilityStore.data.id)
            : undefined;

    // get all the other data
    useDashboardData(facilityId);
    // start 'cycle' timers for incrementing through card stack lists
    useInitializeCycle(facilityId);
    // trigger a new filter action on data if the here maps bounds change
    useRefilter(facilityId);

    return(
        <SC.StyledDashboardPage>
            
            {/* Contains the left hand column of card stacks */}
            <SC.StyledCardsSection>
                <CardsSectionManager />
            </SC.StyledCardsSection>

            {/* Contains the right hand column Here Map */}
            <SC.StyledMapSection>
                <HereMapSectionManager />
            </SC.StyledMapSection>

            {/* An absolute-positioned element displaying the AlgoTraffic logo */}
            <FloatingAlgoLogo />

        </SC.StyledDashboardPage>
    );
    
}

export default DashboardPage;
