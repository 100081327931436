// libraries
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// store
import { setPageControl } from "~/store/page-control/page-control";
// constants
import { travelTimeMaxHeight } from "~/constants";

/*
    Watches all relevant api data stores
    When data changes, we need to ensure that card heights are updated as necessary.
    
    The "pageControl" store value: "cardHeightMap" is used to track this.
*/
export const useCardHeights = () => {
  
    const dispatch = useDispatch();

    // relevant data stores (these are added as effect dependencies below)
    const weatherAlertStore = useSelector( (state: any) => state["weather-alerts"]);
    const alertStore = useSelector( (state: any) => state.alerts);
    const trafficEventStore = useSelector( (state: any) => state["traffic-events"]);
    const travelTimeStore = useSelector( (state: any) => state["travel-times"]);
    const cameraStore = useSelector( (state: any) => state.cameras);

    // store used to track card height allotments
    const pageControlStore = useSelector( (state: any) => state["page-control"]);

    useEffect(
        () => {

            /*  
                The total allowance for "card heights" is 8
                ( a card height is roughly 1/8th view height adjusted for minimal spacing )
                this number is used throughout following logic to track
                how much space can be allotted to subsequent cards  
            */
            let remainingAllowance: number = 8;

            /* BEGIN CRITICAL HEIGHTS*/

                // Initialize height for location card
                let locationHeight: number = initLocationHeight();

                // Initialize height for weather card
                let weatherHeight: number = initWeatherHeight();

                // Initialize height for Alea/Aldot alerts card
                let aleaAldotAlertHeight: number = initAleaAldotHeight(alertStore);

                // critical height is the sum of Location + Weather + Alerts heights
                // critical because they are most sensitive/important for Users
                let totalCriticalHeight: number = 
                    locationHeight + weatherHeight + aleaAldotAlertHeight;

                // note the reduction in remainingAllowance
                remainingAllowance -= totalCriticalHeight;

            /* END CRITICAL HEIGHTS */

            /* BEGIN NON-CRITICAL HEIGHTS*/

                // begin by assuming 0 allowance for non-critical cards.
                let cameraHeight: number = 0;
                let trafficEventHeight: number = 0;
                let travelTimeHeight: number = 0;
                
                let loaded: boolean = cameraStore.loaded && trafficEventStore.loaded && travelTimeStore.loaded;

                // check which data currently exist
                let haveCameras: boolean = cameraStore.data?.length > 0;
                
                let haveTrafficEvents: boolean = trafficEventStore.data?.length > 0;

                let haveTravelTimes: boolean = travelTimeStore.data?.length > 0;

                [trafficEventHeight, remainingAllowance] = 
                    initTrafficEventHeight(loaded, haveTrafficEvents, remainingAllowance);

                [travelTimeHeight, remainingAllowance] =
                    initTravelTimeHeight(loaded, haveTravelTimes, remainingAllowance);

                [cameraHeight, remainingAllowance] =
                    initCameraHeight(loaded, haveCameras, remainingAllowance);

            /* END NON-CRITICAL HEIGHTS*/ 

            /* BEGIN DISTRIBUTING REMAINING ALLOWANCE */

                // now distribute remaining allowance where possible

                if (remainingAllowance >= 1){

                    // attempt to increase traffic events to 4-height
                    if (haveTrafficEvents){
                        trafficEventHeight++;
                        remainingAllowance--;
                    }

                    // dump the remaining allowance into travel times (max 4)
                    if (haveTravelTimes){
                        if (remainingAllowance >= 1){
                            // determine how much height travel times can absorb
                            let capacity: number = travelTimeMaxHeight - travelTimeHeight;
                            // establish whether capacity or remainingAllowance is less
                            let smaller: number = Math.min(remainingAllowance, capacity);
                            // add the lesser to travelTime height
                            travelTimeHeight += smaller;
                            // and deduct the same from remainingAllowance
                            remainingAllowance -= smaller;
                        }
                    }
                }

            /* END DISTRIBUTING REMAINING ALLOWANCE */
            
            // possible extension of logic here...
            let cardHeightMap: {[key: string]: number} = {
                "location": locationHeight,
                "weather": weatherHeight,
                "alea-aldot-alert": aleaAldotAlertHeight,
                "travel-times": travelTimeHeight,
                "traffic-events": trafficEventHeight,
                "cameras": cameraHeight
            };

            dispatch(setPageControl({...pageControlStore, cardHeightMap}))

        }, [
            weatherAlertStore.data,
            alertStore.data,
            travelTimeStore.filteredData,
            trafficEventStore.filteredData,
            cameraStore.filteredData
        ]
    );
};

/******************************************************************************
    HELPERS
******************************************************************************/
// returns the intial height of the Location card
const initLocationHeight = (): number => {

    // Currently, Location card will always have a height of 1
    let height: number = 1;

    return height;
};

// returns the initial height of the Weather card
const initWeatherHeight = (): number => {

    // Currently, Weather is given a minimum height of 1
    // regardless of whether data has successfully loaded
    let height: number = 1;

    return height;
};

// returns the intial height of the AleaAldotAlert card
const initAleaAldotHeight = (
    alertStore: any,
): number => {

    // Alea Alert and Aldot Message card will have 0, 1, or 2 height
    // depending on whether alerts exist and whether their text is long
    let height: number =
        (alertStore.data && alertStore.data.length > 0) 
            ? (alertStore.hasLongText)
                ? 2                     // if exists and is long text
                : 1                     // if exists and is short text
            : 0;                        // if no alerts exist

    return height;
}

// returns the initial height of the TrafficEvent card
const initTrafficEventHeight = (
    loaded: boolean,
    haveData: boolean, 
    remainingAllowance: number
): [height: number, allowance: number] => {

    // traffic events should never be given less than 3-height allowance
    // so if there are ANY traffic events, and allowance is available, allot 3
    if (loaded && haveData && remainingAllowance >= 3)
        return [3, remainingAllowance -= 3];

    else return [0, remainingAllowance];
}

// returns the initial height of the TravelTime card
const initTravelTimeHeight = (
    loaded: boolean,
    haveData: boolean,
    remainingAllowance: number
) => {
    // travel times only require a minimum of 1-height to be fully displayed
    // if there is at least 1 allowance remaining, give it to travel times
    if (loaded && haveData && remainingAllowance >= 1)
        return [1, remainingAllowance -= 1];

    else return [0, remainingAllowance];
}

// returns the initial height of the Camera card
const initCameraHeight = (
    loaded: boolean,
    haveData: boolean,
    remainingAllowance: number
) => {
    // camera snapshots require a minimum of 2-height allowance to properly display
    // if there is at least 2 allowance remaining, give it to camera snapshots
    if (loaded && haveData && remainingAllowance >= 2){
        return [2, remainingAllowance -= 2];
    }

    else return [0, remainingAllowance];
}