// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const HeaderWrapper = styled.div`
    max-height: 64px;
    width: 100%;

    ${flexSmart("flex-start center")}
`;

export const StyledEventTitle = styled.div`

    ${flexSmart("flex-start center")}

    text-align: left;
    font-variant: small-caps;

    ${props => props.theme.typography["heading-3"]}
`;

export const StyledHeaderIcon = styled.div`
    margin-right: 12px;
    ${flexSmart("center center")}
`;