// libraries
import * as React from "react";
import { displayMinutesInHours } from "~/library";
import { extractIntsFromStr, nuon } from "@caps-mobile/common-lib";
// interfaces & models
import { IATTravelTime, EATPlaceType } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import { RoadShield } from "@caps-mobile/traffic-graphics";

export type TravelTimeCardProps = {
    travelTime?: IATTravelTime;
};

export const TravelTimeCard: React.FC<TravelTimeCardProps> = (props) => {

    const { travelTime } = props;

    if (!travelTime)
        return null;

    let shieldText: string = "??";
    let shieldTextArray: string[] = [shieldText];

    let shieldType: EATPlaceType = EATPlaceType.Unknown;

    // it is possible that origin is null, if so skip this section
    if (nuon(travelTime.origin)){

        // pull out only integers from the place name string
        let shieldInts: number[] = 
            extractIntsFromStr(travelTime.origin!.place?.name || "");
        
        // store the parsed integers as strings
        shieldTextArray = 
            shieldInts.map((shieldInt: number) => `${shieldInt}`);

        // override unknown type with type found in origin
        shieldType = travelTime!.origin!.place?.type || EATPlaceType.Unknown;
    }

    return (
        <SC.StyledTravelTimeWrapper>
            
                <SC.StyledName>
                    {`${travelTime.name}`}
                </SC.StyledName>

                <SC.StyledBottomRow>

                    <SC.StyledShield>
                        { 
                            shieldTextArray.map(
                                (shieldText: string, index: number) => {
                                    return( 
                                        <RoadShield 
                                            key={shieldText + `${index}`}
                                            text={shieldText} type={shieldType}
                                        />
                                    );
                                }
                            )
                        }
                    </SC.StyledShield>

                    <SC.StyledTimeSpeedWrapper>

                        <SC.StyledTimeSpeed>
                            {`${displayMinutesInHours(travelTime?.estimatedTimeMinutes) || "??"}`}
                        </SC.StyledTimeSpeed>

                        <SC.StyledTimeSpeed>
                            {`${travelTime?.averageSpeedMph || "??"} mph`}
                        </SC.StyledTimeSpeed>

                    </SC.StyledTimeSpeedWrapper>

                </SC.StyledBottomRow>

                
        </SC.StyledTravelTimeWrapper>
    )
}