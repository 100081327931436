// libraries
import * as React from "react";
// hook & context
import { useSelector } from "react-redux";
import { EThemeOptions, ThemeContext } from "~/theme";
// interfaces & models
import { EATFacilityType } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import { LOCATION_COMPS } from "~/resources/images/graphics/location";
// constants
import { colors_dark, colors_light, iconLocation } from "~/constants";

export type LocationCardProps = {
    //
};

export const LocationCard: React.FC<LocationCardProps> = (props) => {

    const facilityStore = useSelector( (state: any) => state.facility);
    const theme: any = React.useContext(ThemeContext);
    
    if (!facilityStore.data || !facilityStore.data.locations[0])
        return null;
    
    const { name, type } = facilityStore.data;

    const IconComponent: any = determineIcon(theme.mode, type);

    const location = facilityStore.data.locations[0];

    return (
        <SC.StyledLocationWrapper>

            <SC.StyledIconSection>
                {IconComponent}
            </SC.StyledIconSection>

            <SC.StyledTextSection type={type}> 

                <SC.StyledLocationTextPrimary type={type}>
                    {name || location?.city}
                </SC.StyledLocationTextPrimary>

                {
                    (type !== EATFacilityType.Other) &&
                    <React.Fragment>
                        <SC.StyledLocationTextSecondary>
                            {`${location?.displayRouteDesignator} ${location?.direction}`}
                        </SC.StyledLocationTextSecondary >
                        
                        <SC.StyledLocationTextSecondary>
                            {`MP ${location?.linearReference}`}
                        </SC.StyledLocationTextSecondary>
                    </React.Fragment>
                }

            </SC.StyledTextSection>

        </SC.StyledLocationWrapper>
    );
}

const determineIcon = (
    themeMode: EThemeOptions,
    type: EATFacilityType,
) => {

    let width: number = iconLocation.width;
    let height: number = iconLocation.height;

    let [Comp, color]  = determineIconComp(themeMode, type);

    return <Comp width={width} height={height} color={color} />;
}

const determineIconComp = (
    themeMode: EThemeOptions, 
    type: EATFacilityType
): any[] => {

    let Comp;
    let color;

    switch(type){
        case EATFacilityType.WelcomeCenter:
            Comp = LOCATION_COMPS.welcomeCenter;
            color = undefined;
            break;
        case EATFacilityType.RestArea:
            Comp = LOCATION_COMPS.restArea;
            color = undefined;
            break;
        case EATFacilityType.Other:
        default:
            Comp = LOCATION_COMPS.region;
            (themeMode === EThemeOptions.Dark)
                ? color = colors_dark["green_dark"]
                : color = colors_light["green_light"];
    }

    return [Comp, color];
};