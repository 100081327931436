// libraries
import * as React from "react";
import { getRadiusBoxShadow } from "~/library";
// store & context
import { useSelector } from "react-redux";
import { EThemeOptions, ThemeContext } from "~/theme";
// models & interfaces
import { IATAldotMessage, IATAleaAlert } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import { CardStack } from "../../../../views/card-stack/CardStack";
import { AlertCard } from "./alert-cards/AlertCard";
import { CycleView } from "@caps-mobile/cycle-view";
// constants
import { 
    aleaAlertDark, aleaAlertLight, aldotMessageDark, aldotMessageLight
} from "~/constants";

export type IAlertStackProps = {
    //
};

export const AlertStack: React.FC<IAlertStackProps> = (props) => {

    /***************************************************************
        CALCULATED VALUES & BREVITY NAMES
    ***************************************************************/

    // The bgColor value for alerts changes based on Light/Dark theme selected
    const theme: any = React.useContext(ThemeContext);

    const radiusBoxShadow: string = 
        getRadiusBoxShadow(theme.colors["drop-shadow"].rgba);
        
    // create overwrite string based on theme mode and model type
    const cardStyleOverwrite = (alert: IATAleaAlert | IATAldotMessage) => {
        return `background-color: ${
            (theme.mode === EThemeOptions.Dark) 
                ? `${(alert as any).body ? aldotMessageDark : aleaAlertDark};` 
                : `${(alert as any).body ? aldotMessageLight : aleaAlertLight};`
            }`;
    };

    const alertStore = useSelector((state: any) => state.alerts);

    let alertStackList = alertStore.data
        ?   alertStore.data.map(
                (alert: IATAleaAlert | IATAldotMessage) => {
                    return (
                        <SC.StyledAleaAlertStack>
                            <CardStack
                                stackHeight={1}
                                cardStyleOverwrite={cardStyleOverwrite(alert)}
                                cards={[<AlertCard alert={alert || undefined} />]}
                                cardHeights={[alertStore.hasLongText ? 2 : 1]}
                            />
                        </SC.StyledAleaAlertStack>
                    );
                })
        : [];

    /***************************************************************
        PRIMARY RENDER
    ***************************************************************/

    return (
        <CycleView 
            styleOverwrite={radiusBoxShadow}
            displayList={alertStackList}
            displayIndex={alertStore.currentDisplayIndex}
        />
    )
}

export default AlertStack;