// libraries
import * as React from "react";

export enum ESiteNavLinks {
    "/",
    "/welcome",
    "/store-tests",
    "/dashboard",
    "/other"
};

export const SiteNavContext = React.createContext({
    selectedSiteLink: 0,
    setSelectedSiteLink: (newVal: number) => {}
});

export default SiteNavContext;