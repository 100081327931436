// libraries
import * as React from "react";
import { ILocationProps } from "./index";

export const LocationPin: React.FC<ILocationProps> = (props) => {

    const {width, height, viewBox, color} = props;

    return (
        <svg 
            width={width || "226"} height={height || "300"} 
            viewBox={ viewBox || `0 0 226 300`} 
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M98.5819 293.399C68.0685 255.257 0 164.303 0 112.665C0 50.4646 49.8778 0 112.665 0C174.866 0 225.33 50.4646 225.33 112.665C225.33 164.303 156.675 255.257 126.161 293.399C119.12 302.201 105.623 302.201 98.5819 293.399ZM112.665 150.22C133.203 150.22 150.22 133.79 150.22 112.665C150.22 92.1271 133.203 75.11 112.665 75.11C91.5403 75.11 75.11 92.1271 75.11 112.665C75.11 133.79 91.5403 150.22 112.665 150.22Z" 
                fill={color || "#01B561"}
            />
        </svg>
    )
};

export default LocationPin;