import activeWeatherAlerts from "./test-active-weather-alerts.json";
import aleaAlerts from "./test-alea-alerts.json";
import aldotMessages from "./test-aldot-messages.json";
import currentForecast from "./test-current-forecast.json";
import facility from "./test-facility.json";
import trafficEvents from "./test-traffic-events.json";
import travelTimes from "./test-travel-times.json";
import weatherLayer from "./test-weather-layer.json";
import cameras from "./test-cameras.json";
// test images
// import red from "~/resources/test/red-0.png";
// import green from "~/resources/test/green-1.png";
// import blue from "~/resources/test/blue-2.png";
// import yellow from "~/resources/test/yellow-3.png";
// import grey from "~/resources/test/grey-4.png";

// const testImages: string[] = [ red, green, blue, yellow, grey];
// const moddedCameras: any[] = cameras.map( 
//     (camera, index) => { return ({
//         ...camera, 
//         imageUrl: testImages[index]
//     })}
// );

const nextFacilities: any[] = [];

export const TEST_MAP: any = {
    "weather-alerts": activeWeatherAlerts,
    aleaAlerts,
    aldotMessages,
    "current-forecast": currentForecast,
    facility,
    "traffic-events": trafficEvents,
    "travel-times": travelTimes,
    "weather-layer": weatherLayer,
    "cameras": cameras,//moddedCameras,
    "next-facilities": nextFacilities
};