// libraries
import styled from "styled-components";
import { breathe } from "@caps-mobile/styled-lib";
import { nuon } from '@caps-mobile/common-lib';
import { whenMediumMin } from "~/theme";

export type IProps = {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;

    styleOverwrite?: string;
};

export const StyledHereMapSectionManager = styled.div`
    height: 100%;
    width: 100%;

    ${breathe(1, 1.5)}
`;

export const StyledOverlaySection = styled.div<IProps>`
    ${props => `
        position: absolute;
        top: ${nuon(props.top) ? `${props.top}px` : "auto"};
        right: ${nuon(props.right) ? `${props.right}px` : "auto"};
        bottom: ${nuon(props.bottom) ? `${props.bottom}px` : "auto"};
        left: ${nuon(props.left) ? `${props.left}px` : "auto"};

        width: calc(100% - ${(props.right ? props.right : 0) + (props.left ? props.left : 0)}px);
        
        z-index: 1;

        ${props.styleOverwrite ? props.styleOverwrite : ``}
    `}
`;

export const StyledLegend = styled.div`
    width: 216px;
    height: 82px;
    padding: 20px;
    box-sizing: border-box;
    opacity: 75%;

    border-radius: 24px;

    background-color: #1E1E1E;

    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
`;

export const StyledLogoImg = styled.img`
    width: 24px;
    height: 24px;
`;

export const WeatherRadarLegendItems = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

export const WeatherRadarProgressItems = styled.div`
    align-items: center;
    width: 100%;
`;

export const WeatherRadarTime = styled.div`
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
    color: rgba(235,235,245, 1);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
`;

export const WeatherRadarTitle = styled.div`
    width: 63px;
    height: 29px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(235,235,245, 1);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
`;