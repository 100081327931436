// libraries
import { useEffect } from "react";
import { nuon, nuonoe } from "@caps-mobile/common-lib";
import { getForecast } from "~/store/algo-api/current-forecast";
import { 
    getActiveLayer, getActiveLayer_test
} from "~/store/algo-api/weather-layer";
import { getActive, getActive_test } from "~/store/algo-api/weather-alerts";
import { getMapSettings } from "~/library";
// hooks & context
import { useDispatch, useSelector } from "react-redux";
// constants
import { isTesting } from "~/constants";

// api-get: load current weather data to the store
export const useCurrentWeather = () => {

    let currentWeatherInterval: NodeJS.Timeout;

    const dispatch = useDispatch();
    const weatherStore = useSelector( (state: any) => state["current-forecast"] );
    const facilityStore = useSelector( (state: any) => state["facility"] );

    useEffect(() => {

        // check whether currentWeather has already been loaded
        const hasLoadedWeather: boolean = (
            nuonoe(weatherStore.data) || 
            weatherStore.noContent || 
            nuon(weatherStore.error)
        );

        // tracks whether the facility has successfully loaded
        const hasLoadedFacility: boolean = (
            nuonoe(facilityStore.data) || 
            facilityStore.noContent || 
            nuon(facilityStore.error)
        );

        // get the facility's center/zoom/etc.
        const mapSettings = getMapSettings(facilityStore.data);

        // keep track of the facility center for other page logic
        const facilityCenter: {lat: number, lng: number} = {
            lat: mapSettings?.centerLatitude,
            lng: mapSettings?.centerLongitude
        };

        // if there is no data and data is not currently loading...
        if (!weatherStore.loading && !hasLoadedWeather && hasLoadedFacility){
            dispatch(
                getForecast(
                    facilityCenter.lat, 
                    facilityCenter.lng, 
                    facilityStore.data?.id || undefined
                )
            );
        }

        // refresh current weather every 3 minutes
        currentWeatherInterval = setInterval(
            () => {
                dispatch(
                    getForecast(
                        facilityCenter.lat, 
                        facilityCenter.lng, 
                        facilityStore.data?.id || undefined
                    )
                );
            }, (1000 * 60 * 3)
        );
    
        // clean up interval
        return () => {
            if (currentWeatherInterval)
                clearInterval(currentWeatherInterval);
        }

    }, [])
};

// api-get: load active weather alerts to the store
export const useActiveWeatherAlerts = () => {

    const dispatch = useDispatch();
    const weatherStore = useSelector( (state: any) => state["current-forecast"]);
    const facilityStore = useSelector( (state: any) => state["facility"]);

    useEffect( () => {
        
        // check whether current-forecast has already been loaded
        const hasLoadedWeather: boolean = (
            nuonoe(weatherStore.data) || 
            weatherStore.noContent || 
            nuon(weatherStore.error)
        );

        // if the current conditions call successfully loaded
        if (hasLoadedWeather && !nuon(weatherStore.error)){
            if (isTesting){
                dispatch(getActive_test(facilityStore.data.id))
                dispatch(getActiveLayer_test(facilityStore.data.id));
            }
            else{
                dispatch(getActive(weatherStore.data?.zones || []));
                dispatch(getActiveLayer(weatherStore.data?.zones || []));
            }

            return () => {
                //
            }
        }

    }, [facilityStore, weatherStore])
};
