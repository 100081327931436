// libraries
import { useEffect, useState } from "react";
import { buildCameraMarker, buildMarkers, createAnimatedOriginElement } from "~/library";
import { getEventCoords } from "~/store/algo-api/traffic-events";
import { getCameraCoords } from "~/store/algo-api/cameras";
// hooks
import { useSelector } from "react-redux";
// resources
import { getNextFacilitiesCoords } from "~/store/algo-api/next-facilities";

// handles building/rebuilding the marker list on store changes
export const useMarkerList = (
    facilityViewOrigin: any,
    map: H.Map | undefined
): void => {

    const pageStore = useSelector( (state: any) => state["page-control"]);
    const cameraStore = useSelector( (state: any) => state.cameras);
    const trafficEventStore = useSelector( (state: any) => state["traffic-events"]);
    const nextFacilitiesStore = useSelector( (state: any) => state["next-facilities"]);

    const facilityStore = 
        useSelector( (state: any) => state.facility);
    let facilityId: number | undefined = 
        (facilityStore.data && facilityStore.data.id)
            ? parseInt(facilityStore.data.id)
            : undefined;

    const [markerGroup, setMarkerGroup] = 
        useState<H.map.Group | null>(null);

    const showCameras: boolean = 
        (pageStore.cardHeightMap["cameras"] > 0);

    useEffect(
        
        () => {

            let trafficEventMarkers = (trafficEventStore.filteredData?.length === 0)
                ? []
                : buildMarkers(
                    trafficEventStore.filteredData, 
                    trafficEventStore.currentDisplayIndex,
                    getEventCoords,
                    "traffic-events"
                );

            let cameraMarkers: any[] = [];

            if (showCameras){
                cameraMarkers = (cameraStore.filteredData?.length === 0)
                    ? []
                    : buildCameraMarker(
                        cameraStore.filteredData,
                        cameraStore.currentDisplayIndex,
                        getCameraCoords
                    )
            }

            let nextFacilitiesMarkers: any[] = [];
            
            nextFacilitiesMarkers = (nextFacilitiesStore.filteredData?.length === 0)
                ? []
                : buildMarkers(
                    nextFacilitiesStore.filteredData.filter((x: any) => x.id !== facilityId), 
                    nextFacilitiesStore.currentDisplayIndex,
                    getNextFacilitiesCoords,
                    "next-facility"
                );

            // note that the facility marker is always appended as the last item
            // this should ensure that the facility marker renders OVER incidents
            let markersList = [ 
                ...nextFacilitiesMarkers,
                ...trafficEventMarkers,
                ...cameraMarkers
            ];

            let H: any = (window as any).H;
            if (H && map){
                let newMarkerGroup: H.map.Group = new H.map.Group();

                markersList.forEach((marker: H.map.Marker | null) => {
                    if (marker) newMarkerGroup.addObject(marker);
                });

                let domMarker: H.map.DomMarker = new H.map.DomMarker(
                    { lat: facilityViewOrigin.lat, lng: facilityViewOrigin.lng },
                    {
                        icon: createAnimatedOriginElement(H, 38, 38, 20, 20)
                    }
                );

                newMarkerGroup.addObject(domMarker);

                let currentMarkerList = markerGroup;
                // ensure map exists before trying to add markers
                currentMarkerList && map.removeObject(currentMarkerList);
                // ensure there are -some- markers before trying to add markers
                if (newMarkerGroup) {
                    // clear the current contents of markerGroup
                    newMarkerGroup && map.addObject(newMarkerGroup);
                    setMarkerGroup(newMarkerGroup);
                }
            }
        }, 
        [cameraStore, nextFacilitiesStore, trafficEventStore, pageStore.cardHeightMap["cameras"]]
    );
};