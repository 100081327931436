export const breakpointConstants = {
    max: {
        "xxs": 419,
        "xs": 575,
        "sm": 767,
        "md": 991,
        "lg": 1199,
        "xl": 1399
    }, 
};

export const whenSmallMin = (styleString: string) => {
    return `
        @media(min-width: ${breakpointConstants.max.sm + 1}px){
            ${styleString}
        }
    `
};

export const whenMediumMin = (styleString: string) => {
    return `
        @media(min-width: ${breakpointConstants.max.md + 1}px){
            ${styleString}
        }
    `
};

export const whenLargeMin = (styleString: string) => {
    return `
        @media(min-width: ${breakpointConstants.max.lg + 1}px){
            ${styleString}
        }
    `
};

export const whenXLargeMin = (styleString: string) => {
    return `
        @media(min-width: ${breakpointConstants.max.xl + 1}px){
            ${styleString}
        }
    `
};

export const whenXXSmallMax = (styleString: string) => {
    return `
        @media(max-width: ${breakpointConstants.max.xxs}px){
            ${styleString}
        }
    `
};

export const whenXSmallMax = (styleString: string) => {
    return `
        @media(max-width: ${breakpointConstants.max.xs}px){
            ${styleString}
        }
    `
};

export const whenSmallMax = (styleString: string) => {
    return `
        @media(max-width: ${breakpointConstants.max.sm}px){
            ${styleString}
        }
    `
};

export const whenMediumMax = (styleString: string) => {
    return `
        @media(max-width: ${breakpointConstants.max.md}px){
            ${styleString}
        }
    `
};

export const whenLargeMax = (styleString: string) => {
    return `
        @media(max-width: ${breakpointConstants.max.lg}px){
            ${styleString}
        }
    `
};

export const whenXLargeMax = (styleString: string) => {
    return `
        @media(max-width: ${breakpointConstants.max.xl}px){
            ${styleString}
        }
    `
};

export const Breakpoints = {
    breakpointConstants,
    whenXXSmallMax,
    whenXSmallMax,
    whenSmallMax,
    whenMediumMax,
    whenLargeMax,
    whenXLargeMax,
    whenSmallMin,
    whenMediumMin,
    whenLargeMin,
    whenXLargeMin
};

export default Breakpoints;