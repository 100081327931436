// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS } from "~/constants";

export const Sign = styled.div<{isFourHigh: boolean}>`
    height: 100%;
    max-height: 260px;
    width: 116px;

    ${flexSmart("space-between center")}
    flex-direction: column;

    padding: ${props => props.isFourHigh ? "10px" : "8px"};
    box-sizing: border-box;

    background-color: #006B54;

    border: medium solid #fff;

    border-radius: ${BORDER_RADIUS}px;
`;

export const Text = styled.div`
    height: auto;
    width: auto;

    ${flexSmart("center center")}

    font-family: Highway Gothic Narrow;
    font-size: 36px;
    line-height: 32px;
    text-transform: uppercase;
    color: #fff;
`;

export const Shields = styled.div`
    height: 37px;
    width: 100%;

    margin: 4px 0;

    box-sizing: border-box;

    ${flexSmart("center center")}
`;

export const StyledDilineator = styled.div<{styleOverwrite?: string}>`
    height: 1px;
    width: 96%;

    border-top: 2px solid #fff;

    background-clip: padding-box;
    overflow: hidden;

    background-color: #fff;

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;