// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS } from "~/constants";

export const StyledTravelTimeStack = styled.div`
    height: auto;    
    width: 100%;

    box-sizing: border-box;

    border-radius: ${BORDER_RADIUS}px;
`;