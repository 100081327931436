// libraries
import { createSlice } from "@reduxjs/toolkit";
// interfaces & models
import { 
    ForecastNetworkManager as NetworkManager, 
    IProcessedResponse 
} from "@algo/network-manager/managers/v3";
// constants
import { isTesting, tenMileLat } from "~/constants";
import {T_FACILITIES} from "~/test-data";
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../api-endpoint-constants";
import { nuon, nuonoe } from "@caps-mobile/common-lib";
import { buildLastResponse } from "../common-abstracts";
declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).forecasts}`;

export type IInitialState = {
    data: any | null,
    errorMessage: string,
    status: number,
    noContent: boolean;

    lastData: any;
    lastErrorMessage: string;
    lastChecksum: string;
    lastCount: number;
    lastStatus: number;

    loading: boolean;
};

export const initialState: IInitialState = {
    data: null,
    errorMessage: "",
    status: 0,
    noContent: false,

    lastData: null,
    lastErrorMessage: "",
    lastChecksum: "",
    lastCount: 0,
    lastStatus: 0,
    
    loading: false,
};

// create slice
export const forecastSlice = 
    createSlice(
        {
            name: `current-forecast`,
            initialState: initialState,
            reducers: {
                begin: (state) =>  {
                    state.loading = true;
                },
                success: (state, action) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastData = action.payload.data;
                    state.lastErrorMessage = action.payload.errorMessage;
                    state.lastStatus = action.payload.status;
                    state.lastChecksum = action.payload.xChecksum;
                    state.lastCount = action.payload.xCount;

                    state.loading = false;  
                },
                failure: (state, action) => {
                    state.data = null;
                    state.errorMessage = action.payload.errorMessage;
                    
                    state.lastChecksum = "";
                    state.lastCount = 0;
                    state.lastStatus = 0;

                    state.loading = false;
                }
            }
        }
    )

const {
    begin, 
    success,
    failure
} = forecastSlice.actions;

export const getForecast = (
    lat: number,
    lng: number,
    id: number = 12,
    testMode: string = ""
) => {

    if (isTesting)
        return getForecast_test(lat, lng, id, testMode);
    else 
        return getForecast_api(lat, lng);
}


export const getForecast_api = (
    lat: number,
    lng: number
) => {

    return (
        dispatch: any, 
        getState: any
    ) => {

        if (getState()["current-forecast"].loading)
            return;

        let manager = new NetworkManager(apiUrl);

        dispatch(begin);

        manager.getCurrent(
            buildLastResponse(getState()["current-forecast"]), 
            {latitude: lat, longitude: lng}, 
            false
        )
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message}))
                    else {
                        // check if the "success" actually has data, if so, dispatch success
                        if (nuon(response.data)) 
                            dispatch(success(response.getReduxObject()))
                        // if not, trigger retry logic
                        else {
                            dispatch(failure({ errorMessage: "204 forecast data, retrying..."}));
                            dispatch(getForecast_api_retry(lat, lng, 0));
                        }
                    }
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message}))
            )
    }
};

export const getForecast_api_retry = (
    lat: number,
    lng: number,
    retryCount: number
) => {

    return (
        dispatch: any, 
        getState: any
    ) => {

        // do not try more than 4 times (0-indexed)
        if (retryCount >= 4) {
            dispatch(failure({ errorMessage: "Too many forecast retries. No valid weather data available."}));
            return;
        }

        if (getState()["current-forecast"].loading)
            return;

        let manager = new NetworkManager(apiUrl);

        dispatch(begin);

        let newLat: number = lat;

        // first retry is shifted ~10miles north
        if (retryCount === 0){
            newLat = tenMileLat + lat;
        }
        // second, retry is shifted ~10miles south
        else if (retryCount === 1){
            newLat = lat - tenMileLat; 
        }
        // third retry is shifted ~25miles north
        else if (retryCount === 2){
            newLat = tenMileLat + lat;
        }
        // fourth retry is shifted ~25miles south
        else if (retryCount === 3){
            newLat = lat - tenMileLat;
        }

        manager.getCurrent(
            buildLastResponse(getState()["current-forecast"]), 
            {latitude: newLat, longitude: lng}, 
            false
        )
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message}))
                    else {
                        // check if the "success" actually has data, if so, dispatch success
                        if (nuon(response.data))
                            dispatch(success(response.getReduxObject()))
                        // if not, trigger retry logic
                        else {
                            dispatch(failure({ errorMessage: "204 forecast data, retrying..."}));
                            dispatch(getForecast_api_retry(newLat, lng, retryCount++));
                        }
                    }
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message}))
            )
    }
};

export const getForecast_test = (
    lat?: number,
    lng?: number,
    id: number = 12,
    testMode: string = ""
) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()["current-forecast"].loading) return;

        dispatch(begin);

        let currentForecast = 
            (T_FACILITIES[id as keyof typeof T_FACILITIES] as any)["current-forecast"];

        dispatch(success({data: currentForecast}));

    }
};

export const forecastReducer = forecastSlice.reducer;