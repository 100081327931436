// libraries
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { nuon } from "@caps-mobile/common-lib";
// store
import { 
    ActionCreator as EventActionCreator
} from "~/store/algo-api/traffic-events";
import { 
    ActionCreator as NextFacilitiesActionCreator
} from "~/store/algo-api/next-facilities";
import { 
    ActionCreator as CameraActionCreator 
} from "../../../../store/algo-api/cameras";
import { 
    ActionCreator as TravelTimeActionAcreator
} from "../../../../store/algo-api/travel-times";
import { getAlerts } from "../../../../store/algo-api/state-alerts";
// store
import {getById, getById_test} from "../../../../store/algo-api/facilities";
import { getKey } from "~/store/algo-api/weather-radar";
// constants
import {dashboardRefreshInterval, isTesting} from "../../../../constants";

// api-get: load facility data to the store
export const useFacilityData = (facilityIdStr: string) => {

    const dispatch = useDispatch();

    const facilityId: number = parseInt(facilityIdStr);

    useEffect(
        () => {
            // trigger facility data get
            if (facilityId){

                if (isTesting){
                    dispatch(getById_test(facilityId));
                    dispatch(getKey(true));
                }
                else{
                    dispatch(getById(facilityId));
                    dispatch(getKey());
                }
            }

            // trigger facility data refresh interval
            let dashboardRefreshIntervalId = setInterval(
                () => Promise.resolve().then(() => {
                    if (isTesting){
                        dispatch(getById_test(facilityId));
                        dispatch(getKey(true));
                    }
                    else{
                        dispatch(getById(facilityId));
                        dispatch(getKey());
                    }
                }),
                dashboardRefreshInterval
            )

            // cleanup
            return () => {
                clearInterval(dashboardRefreshIntervalId);
            }

        }, [facilityId]
    );
};

// api-get: attempt to retry loading facility data on failure
export const useFacilityErrorRetry = (
    facilityId: string, 
    facilityStore: any
) => {

    const dispatch = useDispatch();

    useEffect(() => {
        if (!facilityStore.loading && facilityStore.error){
            setTimeout(
                () => {
                    Promise.resolve().then(
                        () => {
                            if (facilityId) 
                                if (isTesting)
                                    dispatch(getById_test(parseInt(facilityId)));
                                else 
                                    dispatch(getById(parseInt(facilityId)));
                        }
                    )
                },
                10000
            )
        }
    }, [facilityId, facilityStore])
};

// api-get: load additional data to the store 
// (traffic events, travel times, alea alerts/aldot messages)
export const useDashboardData = (facilityId: number | undefined) => {

    const dispatch = useDispatch();
    
    useEffect(
        () => {

            if (nuon(facilityId)){

                let cameraHandler = new CameraActionCreator(facilityId!, isTesting);
                let eventHandler = new EventActionCreator(facilityId!, isTesting);
                let nextFacilityHandler = new NextFacilitiesActionCreator(facilityId!, isTesting);
                let travelTimeHandler = new TravelTimeActionAcreator(facilityId!, isTesting);

                dispatch(getAlerts());
                dispatch(eventHandler.getById());
                dispatch(travelTimeHandler.getById());
                dispatch(cameraHandler.getById());
                dispatch(nextFacilityHandler.getById());
            }
            
        }, [facilityId]
    );
};

// when map bounds update, trigger a new data filter action for each card type
export const useRefilter = (facilityId: number | undefined) => {

    const dispatch = useDispatch();
    const mapStore = useSelector( (state: any) => state["here-map-state"]);
    
    useEffect(
        () => {

            if (nuon(facilityId)){

                let cameraHandler = new CameraActionCreator(facilityId!, isTesting);
                let eventHandler = new EventActionCreator(facilityId!, isTesting);
                let nextFacilityHandler = new NextFacilitiesActionCreator(facilityId!, isTesting);
                let travelTimeHandler = new TravelTimeActionAcreator(facilityId!, isTesting);

                dispatch(eventHandler.filterData());
                dispatch(travelTimeHandler.filterData());
                dispatch(cameraHandler.filterData());
                dispatch(nextFacilityHandler.filterData());
            }
            
        }, [facilityId, mapStore.bounds]
    );
};
