// libraries
import { createSlice } from "@reduxjs/toolkit";
// constants
const LAT_INSET: number = 0.0004;
const LNG_INSET: number = 0.0001;

type IInitialState = {
    bounds: {latitude: number; longitude: number}[];
};

const initialState: IInitialState = {
    bounds: [],
}

// create list slice
export const hereMapSlice = createSlice(
    {
        name: `here-map`,
        initialState: initialState,
        reducers: {
            setBoundsAction: (state, action) => {
                state.bounds = action.payload.bounds
            },
        }
    }
);

const extractBoundsArray = (bounds: any) => {

    let boundsArray: number[] = [];

    if (!Array.isArray(bounds) || !bounds.length) {
        return boundsArray;
    }

    boundsArray.push(bounds[0] - (bounds[0] * LNG_INSET));
    
    boundsArray.push(bounds[1] - (bounds[1] * LAT_INSET));

    boundsArray.push(bounds[2] + (bounds[2] * LNG_INSET));

    boundsArray.push(bounds[3] + (bounds[3] * LAT_INSET));

    return boundsArray;
};

export const createBoundsPolygon = (bounds: any) => {

    if (!bounds || bounds.length < 4)
        return null;

    return {
        "type": "Feature",
        "properties": {
            "zIndex": 999,
            "category": {
                "fillColor": {
                    "red": 230,
                    "green": 102,
                    "blue": 55,
                    "alpha": 0.2,
                    "hex": "E66637"
                },
                "strokeColor": {
                    "red": 230,
                    "green": 102,
                    "blue": 55,
                    "alpha": 1.0,
                    "hex": "E66637"
                }
            },
        },
        "geometry": {
            "type": "Polygon",
            "coordinates": [
            [
                [
                bounds[0].longitude,
                bounds[0].latitude
                ],
                [
                bounds[1].longitude,
                bounds[1].latitude
                ],
                [
                bounds[2].longitude,
                bounds[2].latitude
                ],
                [
                bounds[3].longitude,
                bounds[3].latitude
                ]
            ]
            ]
        }
        }
}

// actions
export const { 
    setBoundsAction,
} = hereMapSlice.actions;

// action creators
export const setBounds = (
    map: any
) => {

    return (
        dispatch: any,
    ) => {

        if (map){
            var boundingBox = map?.getViewModel().getLookAtData().bounds.getBoundingBox();
            var SWlong = boundingBox?.getLeft();
            var SWlat = boundingBox?.getBottom();
            var NElong = boundingBox?.getRight();
            var NElat = boundingBox?.getTop();
            let mapBounds: any = [SWlong, SWlat, NElong, NElat];

            let bounds: any = extractBoundsArray(mapBounds);
            dispatch(setBoundsAction({bounds}));
        }
    }
}

export const hereMapReducer = hereMapSlice.reducer;