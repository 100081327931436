// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { EThemeOptions, whenMediumMin } from "~/theme";
// constants
import { colors_dark, colors_light } from "~/constants";

export const StyledWelcome = styled.div`
    width: 100%;
    height: 100%;

    padding: 2%;
    box-sizing: border-box;

    ${flexSmart("center center")}
    flex-direction: column;

    ${whenMediumMin(`
        flex-direction: row;
        padding: 10%;
    `)}
`;

export const StyledLogoImg = styled.img`
    width: 156px;
    height: 156px;

    ${whenMediumMin(`
        margin-right: 16px;
    `)}
`;

export const StyledTextColumn = styled.div`
    ${flexSmart("flex-start center")}
    flex-direction: column;

    color: ${props => (props.theme.mode === EThemeOptions.Dark) 
        ? colors_dark.algo_green_text 
        : colors_light.algo_green_text
    };
`;

export const VisitText = styled.div`
    width: 100%;
    margin-top: 12px;
    margin-bottom: 8px;

    ${flexSmart("center center")}

    font-size: 28px;
    font-weight: 700;
    text-align: center;

    ${whenMediumMin(`
        margin-top: 0;
        
        font-size: 48px;
        justify-content: flex-start;
        text-align: left;
    `)}

`;

export const AdditionalText = styled.div`
    width: 100%;
    ${flexSmart("center center")}

    font-size: 24px;
    font-weight: 600;
    text-align: center;

    ${whenMediumMin(`
        font-size: 30px;
        justify-content: flex-start;
        text-align: left;
    `)}

`;