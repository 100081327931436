import { configureStore } from "@reduxjs/toolkit";
import { listReducer as alertsReducer } from "./algo-api/state-alerts";
import { facilityReducer as facilityReducer } from "./algo-api/facilities";
import { forecastReducer } from "./algo-api/current-forecast";
import { weatherAlertReducer } from "./algo-api/weather-alerts";
import { weatherLayerReducer } from "./algo-api/weather-layer";
import { hereMapReducer } from "./here-map/dashboard-map";
import { trafficEventReducer } from "./algo-api/traffic-events";
import { travelTimeReducer } from "../retired-code/old-store/travel-times";
import { pageControlReducer } from "./page-control/page-control";
import { cameraReducer } from "./algo-api/cameras";
import { nextFacilitiesReducer } from "./algo-api/next-facilities";
import { weatherRadarReducer } from "./algo-api/weather-radar";

export default configureStore({
    reducer: {
        // facility data
        facility: facilityReducer,
        // additional kiosk data
        alerts: alertsReducer,
        "current-forecast": forecastReducer,
        "weather-alerts": weatherAlertReducer,
        "weather-layer": weatherLayerReducer,
        "weather-radar": weatherRadarReducer,
        "traffic-events": trafficEventReducer,
        "travel-times": travelTimeReducer,
        "cameras": cameraReducer,
        "next-facilities": nextFacilitiesReducer,
        // here-map
        "here-map-state": hereMapReducer,
        // page-control
        "page-control": pageControlReducer
    }
});


/*  
    if you NEED to store non-serializable objects in the redux store
    you can paste the below code in the configureStore object above
    immediately after reducer's value. 
    note that this is considered naughty and you should know what you are doing 
*/

// middleware: (getDefaultMiddleware) => 
//     getDefaultMiddleware({
//         serializableCheck: false
//     })