// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// types & models
import { BORDER_RADIUS } from "~/constants";

export const StyledWeatherWrapper = styled.div`
    height: 100%;

    
    @media(max-height: 1000px) and (min-height: 916px){
        height: 115%;
    }

    width: 100%;

    ${flexSmart("space-between center")}
    flex-direction: column;
`;

export const StyledTopRow = styled.div`
    width: 100%;
    height: auto;

    ${flexSmart("space-between center")}
`;

export const IconTempWrapper = styled.div`
    width: auto;
    height: auto;

    ${flexSmart("flex-start center")}
`;

export const StyledTemperature = styled.div<{noAlerts: boolean}>`
    width: auto;
    height: 100%;

    font-weight: 600;
    color: ${props => props.theme.colors["text-primary"].rgba};

    ${props => `
        ${props.noAlerts 
            ? `
                font-size: 64px;
                line-height: 69px;
            `
            : `
                font-size: 48px;
                line-height: 52px;    
            ` 
        }
    `}
`;

export const StyledIcon = styled.div`
    width: auto;
    height: 100%;

    margin-right: 16px;
`;

export const DateTimeWrapper = styled.div`
    width: auto;
    height: 100%;

    ${flexSmart("center flex-end")}
    flex-direction: column;
`;

export const DateRow = styled.div<{noAlerts?: boolean}>`
    width: auto;
    height: auto;

    ${props => props.noAlerts 
        ? `
            font-size: 32px;
            font-weight: 600;
            line-height: 36px;
        ` 
        : `
            font-size: 28px;
            font-weight: 600;
            line-height: 28px;
        `
    }
`;

export const TimeRow = styled.div<{noAlerts?: boolean}>`
    width: auto;
    height: auto;

    ${props => props.noAlerts 
        ? `
            font-size: 32px;
            font-weight: 600;
            line-height: 36px;
        ` 
        : `
            font-size: 28px;
            font-weight: 600;
            line-height: 32px;
        `
    }
`;

export const StyledBottomRow = styled.div`
    width: 100%;
    height: 35px;

    ${flexSmart("flex-start center")}

    @media(max-height: 915px){
        display: none;
    }
`;

export const StyledMultiAlerts = styled.div`
    width: 100%;
    height: 100%;

    margin-top: 32px;

    ${flexSmart("space-around center")}
    flex-direction: column;

    @media(max-height: 915px){
        display: none;
    }
`;

export const StyledShortForecast = styled.div`
    width: 100%;
    height: 100%;

    ${flexSmart("flex-start flex-start")}

    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const StyledAlertBar = styled.div<{color: string, bgColor: string}>`
    height: 35px;
    width: 100%;

    box-sizing: border-box;
    padding: 5px;
    border-radius: ${BORDER_RADIUS}px;

    background-clip: padding-box;
    -webkit-background-clip: padding-box;

    background-color: ${props => props.bgColor};

    ${props => props.theme.typography["heading-4"]};
    color: ${props => props.color};
`;