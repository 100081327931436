// libraries
import * as React from "react";
import { mapConditionIcon } from "~/resources/weather-type-icons";
import { nuonoe } from "@caps-mobile/common-lib";
import { buildDefaultDateString, buildDefaultTimeString } from "~/library";
// hooks
import { useSelector } from "react-redux";
// styles
import * as SC from "./Styled";
// interfaces & models
import {
    ECWWeatherConditionIcon, ICWAlert, ICWForecastPeriod
} from "@algo/network-manager/models/v3/cw";
// components
import { ActiveAlert } from "./ActiveAlert";
import { CycleView } from "@caps-mobile/cycle-view";

export type WeatherCardProps = {
    currentConditions: any;
    isExtended: boolean;
};

export const WeatherConditions: React.FC<WeatherCardProps> = (props) => {

    const { currentConditions, isExtended } = props;

    const alertsStore = 
         useSelector( (state: any) => state["weather-alerts"]);

    const activeAlerts: ICWAlert[] = alertsStore.data || [];

    const current: ICWForecastPeriod | undefined = currentConditions;

    const conditionIcon: string =
        mapConditionIcon(
            current?.conditionIcon || ECWWeatherConditionIcon.Unknown, 
            !current?.isDaytime
        );

    const noAlerts: boolean = !nuonoe(activeAlerts);

    let dateString: string = buildDefaultDateString();
    let timeString: string = buildDefaultTimeString();

    /***************************************************************
        EFFECTS: 
    ***************************************************************/

    return (
        <SC.StyledWeatherWrapper>

            <SC.StyledTopRow>

                <SC.IconTempWrapper>

                    <SC.StyledIcon>
                        <img 
                            src={conditionIcon} 
                            width={noAlerts ? 80 : 65} 
                            height={noAlerts ? 64 : 52} 
                        />
                    </SC.StyledIcon>

                    <SC.StyledTemperature noAlerts={noAlerts}>
                        {`${current ? current.temperature : "??"}`}
                        <span>&#176;</span>
                    </SC.StyledTemperature>

                </SC.IconTempWrapper>

                <SC.DateTimeWrapper>
                    
                    <SC.DateRow noAlerts={noAlerts}>
                        {dateString}
                    </SC.DateRow>

                    <SC.TimeRow noAlerts={noAlerts}>
                        {timeString}
                    </SC.TimeRow>

                </SC.DateTimeWrapper>

            </SC.StyledTopRow>


            { !isExtended && 
                <SC.StyledBottomRow>
                    {
                        noAlerts &&
                        <SC.StyledShortForecast>
                            {`${current ? current.shortForecast : "Unknown"}`}
                        </SC.StyledShortForecast>
                    }

                    {
                        activeAlerts &&
                        <CycleView
                            bleedProtect={true}
                            displayList={
                                activeAlerts.map( 
                                    (alert: ICWAlert, index: number) => 
                                        <ActiveAlert 
                                            key={`${alert.id}${index}`} 
                                            alert={alert}
                                        />
                                )
                            }
                            displayIndex={alertsStore.currentDisplayIndex}
                        />
                    }
                </SC.StyledBottomRow>
            }
           
            {   isExtended &&
                <SC.StyledMultiAlerts>
                    {
                        activeAlerts.map( 
                            (alert: ICWAlert, index: number) => 
                                <ActiveAlert 
                                    key={`${alert.id}${index}`} 
                                    alert={alert}
                                />
                        )
                    }
                </SC.StyledMultiAlerts>
            }
            
        </SC.StyledWeatherWrapper>
    )
}

export default WeatherConditions;