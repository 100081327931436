// libraries
import * as React from "react";
// types & models
import { 
    IATTrafficEvent, IATLaneDirection, EATSeverity, D_ATSignStyle 
} from "@algo/network-manager/models/v3";
// components 
import { Header } from "./header/Header";
import { Signage } from "./signage/Signage";
import { Lanes } from "./lanes/Lanes";
import { RoadClosed } from "~/resources/algo-traffic-icons/lanes/RoadClosed";
// styles
import * as SC from "./Styled";

export type IProps = {
    trafficEvent: IATTrafficEvent;
};

export const TrafficEvent: React.FC<IProps> = (props) => {

    const { trafficEvent } = props;

    const verifiedDirection: IATLaneDirection | null =
        trafficEvent.laneDirections
            ? trafficEvent.laneDirections[0]
            : null;

    const isClosed: boolean = 
        trafficEvent.severity === EATSeverity.Closed;

    const {backgroundColor, glyphColor} = trafficEvent.signStyle || D_ATSignStyle;

    return (
        <SC.TrafficEventWrapper>

            <Header eventType={trafficEvent.type} title={trafficEvent.title} />

            <Signage trafficEvent={trafficEvent} />

            <SC.LanesRow>

                {/* check if event is severity: closed if so, just a sign*/}
                {
                    isClosed &&
                        <RoadClosed 
                            bgColor={`#${backgroundColor.hex}`} 
                            textColor={`#${glyphColor.hex}`} 
                            borderColor={`#${glyphColor.hex}`} 
                        />
                }

                {/* if road open: render the lanes */}
                { !isClosed && verifiedDirection && 
                        <Lanes direction={verifiedDirection} />
                }

            </SC.LanesRow>

        </SC.TrafficEventWrapper>
    );
};

export default TrafficEvent;