// libraries
import * as React from "react";
import { ILocationProps } from "./index";

export const RestArea: React.FC<ILocationProps> = (props) => {

    const {width, height, viewBox, color} = props;

    return (

        <svg
            width={width || "300"} height={height || "300"}
            viewBox={viewBox || `0 0 300 300`}
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="6" y="6" width="288" height="288" rx="44" fill={color || "#155196"}/>
            <rect x="6" y="6" width="288" height="288" rx="44" stroke="white" strokeWidth="12"/>
            <rect x="6" y="6" width="288" height="288" rx="44" stroke="white" strokeWidth="12"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M81.5127 88.8364H220.254V75H81.5127V88.8364Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M35.7617 138.999H79.523V127.309H35.7617V138.999Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M219.756 138.999H263.517V127.309H219.756V138.999Z" fill="white"/>
            <path 
                fillRule="evenodd" clipRule="evenodd" 
                d="M263.517 143.472H201.267L185.153 92.8942H162.713L178.827 143.472H119.465L135.581 92.8942H113.141L97.0249 143.472H35.7617V157.311H92.6149L70.7224 226.023H93.1624L115.055 157.311H183.237L205.129 226.023H227.569L205.677 157.311H263.517V143.472Z" 
                fill="white"
            />
        </svg>

    )
};

export default RestArea;