// libraries
import React from 'react';
// styles
import * as SC from "./Styled";
// enums
import { EStackPosition } from "./enums";
// components

export type IProps = {
    stackPosition?: EStackPosition;
    styleOverwrite?: string;
    cardHeight?: number;
} & React.HTMLAttributes<HTMLElement>;
  
export const StackableCard: React.FC<IProps> = (props) => {

    return (
        <SC.StyledStackableCard {...props}>
            {props.children}
        </SC.StyledStackableCard>
    );
};

export default StackableCard;