// libraries
import * as React from "react";


export type ITrafficArrow = {
    width?: string;
    height?: string;
    viewBox?: string;
    fill?: string;
    pathFill?: string;
    pathFillOpacity?: string;
};

export const TrafficArrow: React.FC<ITrafficArrow> = (props) => {

    const {
        width, height, viewBox, fill, pathFill, pathFillOpacity
    } = props;

    return (
        <svg 
            width={width || "180"} height={height || "74"} viewBox={viewBox || "0 0 180 74"} 
            fill={fill || "none"} xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M5.83203 32C3.07061 32 0.832031 34.2386 0.832031 37C0.832031 39.7614 3.07061 42 5.83203 42V32ZM177.702 40.5355C179.655 38.5829 179.655 35.4171 177.702 33.4645L145.882 1.64466C143.93 -0.307961 140.764 -0.307961 138.811 1.64466C136.858 3.59728 136.858 6.76311 138.811 8.71573L167.095 37L138.811 65.2843C136.858 67.2369 136.858 70.4027 138.811 72.3553C140.764 74.308 143.93 74.308 145.882 72.3553L177.702 40.5355ZM5.83203 42H174.166V32H5.83203V42Z" 
                fill={pathFill || "white"} 
                fillOpacity={pathFillOpacity || "0.75"}
            />
        </svg>
    )
}
    
export default TrafficArrow;
