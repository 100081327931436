// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/images/graphics/interfaces";

export const Incident: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "300"} height={height || "300"} 
            viewBox={viewBox || "0 0 300 300"} 
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" fill="#FCD116"/>
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" stroke="white" strokeWidth="12"/>
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" stroke="black" strokeWidth="12"/>
            <path d="M165.215 70.5699V128.049L157.658 182.78H142.544L134.529 128.049V70.5699H165.215ZM165.215 211.634C165.215 215.756 163.689 219.344 160.635 222.397C157.582 225.298 153.918 226.748 149.643 226.748C145.521 226.748 141.934 225.221 138.88 222.168C135.98 219.115 134.529 215.603 134.529 211.634C134.529 207.359 135.98 203.695 138.88 200.642C141.934 197.589 145.521 196.062 149.643 196.062C153.918 196.062 157.582 197.589 160.635 200.642C163.689 203.695 165.215 207.359 165.215 211.634Z" fill="black"/>
        </svg>
    );
};

export default Incident;