// libraries
import { nuonoe } from "@caps-mobile/common-lib";
import { createSlice, Slice } from "@reduxjs/toolkit";
import { filterOutOfBoundsObjects } from "~/library";
// types & models
import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { T_FACILITIES } from "~/test-data";
import { buildLastResponse } from "../common-abstracts";
// test images
// import redAlt from "~/resources/test/red-0-alt.png";
// import greenAlt from "~/resources/test/green-1-alt.png";
// import blueAlt from "~/resources/test/blue-2-alt.png";

// const testImages: any[] = [redAlt, greenAlt, blueAlt];


export type IFilteredDataInitialState = {
    data: any[],
    errorMessage: string,
    status: number,
    noContent: boolean;

    loaded: boolean;

    lastData: any[];
    lastErrorMessage: string;
    lastChecksum: string;
    lastCount: number;
    lastStatus: number;

    loading: boolean;
    currentDisplayIndex: number;
    filteredData: any[];
};

export const initialState: IFilteredDataInitialState = {
    data: [],
    errorMessage: "",
    status: 0,
    noContent: false,

    loaded: false,

    lastData: [],
    lastErrorMessage: "",
    lastChecksum: "",
    lastCount: 0,
    lastStatus: 0,
    
    loading: false,
    currentDisplayIndex: 0,
    filteredData: []
};

// create list slice
export const filteredDataSlice = (
    name: string
) => {

    return createSlice({
        name,
        initialState,
        reducers: {
            begin: (state: any) => {
                state.loading = true;
            },
            success: (state: any, action: any) => {
                state.data = action.payload.data || [];
                state.filteredData = action.payload.data || [];
                state.errorMessage = action.payload.errorMessage;
                state.status = action.payload.status;
                state.noContent = !nuonoe(action.payload.data);

                state.loaded = true;

                state.lastChecksum = action.payload.xChecksum;
                state.lastCount = action.payload.xCount;
                state.lastErrorMessage = action.payload.errorMessage;
                state.lastData = action.payload.data || [];
                state.lastStatus = action.payload.lastStatus;

                state.loading = false;
            },
            failure: (state: any, action: any) => {
                state.data = [];
                state.filteredData = [];
                state.errorMessage = action.payload.errorMessage;

                state.loaded = true;

                state.lastData = [];
                state.lastErrorMessage = action.payload.errorMessage;

                state.loading = false;
            },
            filterDataAction: (state: any, action: any) => {
                state.filteredData = action.payload.filteredData
            },
            incrementDisplayIndex: (state: any) => {
                // should never be called if the index is >= length of display list
                // so don't bother checking again, just increment by 1
                state.currentDisplayIndex = state.currentDisplayIndex + 1;
            },
            resetDisplayIndex: (state: any) => {
                state.currentDisplayIndex = 0;
            }
        }
    });
}

export class FilteredDataActionCreators{

    sliceName: string;
    slice: Slice<IFilteredDataInitialState> | null = null;
    actions: any;
    testMode: boolean;
    facilityId: number;
    getFunction: any;

    refreshTimeoutId: NodeJS.Timeout | null;
    
    constructor(
        sliceName: string,
        facilityId: number,
        getFunction: any,
        testMode: boolean = false,
    ){
        this.sliceName = sliceName;
        this.slice = filteredDataSlice(sliceName);
        this.actions = this.slice.actions;

        this.facilityId = facilityId;
        this.getFunction = getFunction;
        
        this.testMode = testMode;

        this.refreshTimeoutId = null;
    };

    public incrementIndex(){
        
        return (
            dispatch:any,
            getState: any
        ) =>{

            if (this.actions){

                let curIndex = getState()[this.sliceName].currentDisplayIndex;
                let filteredLength = getState()[this.sliceName].filteredData.length;

                // increment if data length > 1 && < max
                if ((filteredLength > 1) && ( (filteredLength - 1) > curIndex ) ){
                    dispatch(this.actions.incrementDisplayIndex());
                }

                // if there is only 1 data item in the list, or the list is empty 
                // wait at least 1 minute before requesting data again
                else if (filteredLength <= 1){

                    if (this.refreshTimeoutId === null){
                        this.refreshTimeoutId = setTimeout(
                            () => {
                                this.refreshTimeoutId = null;
                                dispatch(this.getById());
                            },
                            (60 * 1000)
                        )
                    }
                }

                // if there are multiple data items, and max cycle index is reached
                // reset the cycle index and refresh data
                else {
                    // if max, set to 0
                    dispatch(this.actions.resetDisplayIndex());

                    // and get new data
                    dispatch(this.getById());
                }
            }

        }
    };

    public resetIndex(){
        return (
            dispatch: any, 
            getState: any
        ) => {

            this.actions &&
                dispatch(this.actions.resetDisplayIndex());
        }
    };

    getById_api(facilityId: number){
        
        return (
            dispatch: any, 
            getState: any
        ) => {
    
            dispatch(this.actions.begin());
    
            this.getFunction(
                buildLastResponse(getState()[this.sliceName]), 
                {id: facilityId}, 
                false
            )
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(this.actions.failure({ errorMessage: response.error.message }));
                        else {
                            dispatch(this.actions.success(response.getReduxObject()));
                            dispatch(this.filterData());
                        }
                            
                    }
                ).catch(
                    (error: Error) => dispatch(this.actions.failure({ errorMessage: error.message}))
                )
        }
    };

    getById_test(id: number, mode?: string){
        
        switch(mode){
            case "error":
                // produce an error
                return (dispatch: any, getState: any) => {
                    dispatch(
                        this.actions.failure({ 
                            errorMessage: "getById_test error", 
                            data: null 
                        })
                    );
                };
            case "empty":
                // produce an empty/noContent
                return (dispatch: any, getState: any) => {
                    dispatch(this.actions.success({ data: null } ));
                }
            default:
                // produce a normal response using the test object/data
                let testData = T_FACILITIES[id as keyof typeof T_FACILITIES];
                if (!testData) 
                    return (dispatch: any, getState: any) => 
                        dispatch(
                            this.actions.failure({
                                errorMessage: "getById_test default error", 
                                data: null
                            })
                        );

                let data = (testData as any)[this.sliceName];

                return (dispatch: any, getState: any) => {
                    dispatch(this.actions.success({data}));
                    dispatch(this.filterData());

                    // setTimeout(
                    //     () => {
                    //         const moddedCameras: any[] = data.map( 
                    //             (camera: any, index: number) => { return ({
                    //                 ...camera, 
                    //                 imageUrl: testImages[index]
                    //             })}
                    //         );
                    //         //console.log("dispatching updated image srcs");
                    //         dispatch(this.actions.success({data: moddedCameras}))
                    //         dispatch(this.filterData());
                    //     }, 20000
                    // )
                }
        }
    };

    getById(test: string = ""){
        return (
            dispatch: any, 
            getState: any
        ) => {

            if (this.refreshTimeoutId){
                clearTimeout(this.refreshTimeoutId);
                this.refreshTimeoutId = null;
            }

            if (this.testMode)
                return dispatch(this.getById_test(this.facilityId, test));
            else 
                return dispatch(this.getById_api(this.facilityId));
        }
    };

    protected filterData(
        getCoords?: (object: any) => {lat: number, lng: number} | null
    ){

        return (dispatch: any, getState: any) => {
            let bounds = getState()["here-map-state"].bounds;
            let data = getState()[this.sliceName].data;
    
            if (bounds && bounds.length > 0 && data && data.length > 0 && getCoords){
                dispatch(this.actions.filterDataAction({
                    filteredData: [
                        ...filterOutOfBoundsObjects(
                            data, 
                            bounds, 
                            getCoords
                        )
                    ]
                }));
            }

            else {
                dispatch(this.actions.filterDataAction({filteredData: data}));
            }
        }
    };

}