// libraries
import { 
    AleaAlertNetworkManager as AleaNetworkManager,
    AldotMessageNetworkManager as AldotNetworkManager, 
    IProcessedResponse, ProcessedResponse, 
    IAleaAlertNetworkManager, IAldotMessageNetworkManager 
} from "@algo/network-manager/managers/v3";
import { getAll as _getAll } from "../common-abstracts";
// models & interfaces
import {
    ATAldotMessage, ATAleaAlert, EATAleaAlertType, EATAudience, IATAldotMessage, 
    IATAldotMessageDto, IATAleaAlert, IATAleaAlertDto 
} from "@algo/network-manager/models/v3";
// constants
import {T_FACILITIES}  from "~/test-data";
import { isTesting, LONG_ALERT_TEXT } from "~/constants";
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../api-endpoint-constants";
import { createSlice } from "@reduxjs/toolkit";
declare var __API_URL__: string;
const aleaApiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).aleaAlerts}`;
const aldotApiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).aldotMessages}`;

// tracks the refreshTimeout (if one exists) to prevent duplicate
// refresh calls by canceling any queued up call when refreshing
var refreshTimeoutId: NodeJS.Timeout | null = null;

// tracks the last id used to get alerts (this is only valid in testMode for Alerts)
var lastRequestId: number = 12;

export type IStateAlertState = {
    data?: any[] | null,
    errorMessage?: string,
    status: number,
    noContent: boolean,

    lastAleaData: any[] | null,
    lastAleaErrorMessage: string,
    lastAleaChecksum: string,
    lastAleaCount: number,
    lastAleaStatus: number,

    lastAldotData: any[] | null,
    lastAldotErrorMessage: string,
    lastAldotChecksum: string,
    lastAldotCount: number,
    lastAldotStatus: number,

    loading: boolean,
    currentDisplayIndex: number,
    hasLongText: boolean
};

const initialState: IStateAlertState = {
    data: null,
    errorMessage: "",
    status: 0,
    noContent:false,

    lastAleaData: [],
    lastAleaErrorMessage: "",
    lastAleaChecksum: "",
    lastAleaCount: 0,
    lastAleaStatus: 0,

    lastAldotData: [],
    lastAldotErrorMessage: "",
    lastAldotChecksum: "",
    lastAldotCount: 0,
    lastAldotStatus: 0,

    loading: false,
    currentDisplayIndex: 0,
    hasLongText: false
};

// create slice
export const alertsSlice = 
    createSlice(
        {
            name: `alerts`,
            initialState,
            reducers: {
                begin: (state) =>  {
                    state.loading = true
                },
                success: (state, action) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = !action.payload.data;

                    state.lastAleaData = action.payload.lastAleaResponse?.data;
                    state.lastAleaErrorMessage = action.payload.lastAleaResponse?.errorMessage;
                    state.lastAleaChecksum = action.payload.lastAleaResponse?.xChecksum;
                    state.lastAleaCount = action.payload.lastAleaResponse?.xCount;
                    state.lastAleaStatus = action.payload.lastAleaResponse?.status;

                    state.lastAldotData = action.payload.lastAldotResponse?.data;
                    state.lastAldotErrorMessage = action.payload.lastAldotResponse?.errorMessage;
                    state.lastAldotChecksum = action.payload.lastAldotResponse?.xChecksum;
                    state.lastAldotCount = action.payload.lastAldotResponse?.xCount;
                    state.lastAldotStatus = action.payload.lastAldotResponse?.status;

                    state.loading = false;
                    state.currentDisplayIndex = 0;
                    state.hasLongText = action.payload.hasLongText || false;
                },
                failure: (state, action) => {
                    state.data = null;
                    state.errorMessage = action.payload.errorMessage;
                    state.status = action.payload.status;
                    state.noContent = false;

                    state.lastAleaData = null;
                    state.lastAleaErrorMessage = action.payload.errorMessage;
                    state.lastAleaChecksum = "";
                    state.lastAleaCount = 0;
                    state.lastAleaStatus = 0;

                    state.lastAldotData = null;
                    state.lastAldotErrorMessage = action.payload.errorMessage;
                    state.lastAldotChecksum = "";
                    state.lastAldotCount = 0;
                    state.lastAldotStatus = 0;

                    state.loading = false;
                    state.hasLongText = false;
                },
                incrementDisplayIndex: (state) => {
                    // should never be called if the index is >= length of display list
                    // so don't bother checking again, just increment by 1
                    state.currentDisplayIndex = state.currentDisplayIndex + 1;
                },
                resetDisplayIndex: (state) => {
                    state.currentDisplayIndex = 0;
                }
            }
        }
    )

const {
    begin, 
    success,
    failure,
    incrementDisplayIndex,
    resetDisplayIndex
} = alertsSlice.actions;

export const incrementIndex = (
    dispatch: any,
    getState: any
) => {

    let curIndex: number = getState().alerts.currentDisplayIndex;
    let dataLength = getState().alerts.data.length;

    // increment if data length > 1 && < max
    if ( (dataLength > 1) && ( (dataLength - 1) > curIndex )){
        dispatch(incrementDisplayIndex());
    }

    // if there is only 1 data item in the list, wait at least 
    // 1 minute before requesting data again
    else if (dataLength === 1){

        if (refreshTimeoutId === null){
            refreshTimeoutId = setTimeout(
                () => {
                    dispatch(getAlerts(lastRequestId));
                },
                (60 * 1000)
            )
        }
    }

    // if there are multiple data items, and max cycle index is reached
    // reset the cycle index and refresh data
    else {
        // if max, set to 0
        dispatch(resetDisplayIndex());

        // and get new data
        dispatch(getAlerts(lastRequestId));
    }
}

export const resetIndex = (
    dispatch: any,
    getState: any
) => {
    dispatch(resetDisplayIndex);
}

export const getAll_api = (
    id: number = 12
) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let aleaManager: IAleaAlertNetworkManager = 
            new AleaNetworkManager(aleaApiUrl);

        let aldotManager: IAldotMessageNetworkManager = 
            new AldotNetworkManager(aldotApiUrl);

        dispatch(begin());

        let requestList: Promise<any>[] = [];

        requestList.push(
            aleaManager.getAll(
                buildLastStateResponse(getState().alerts, "Alea"),
                {type: [`${EATAleaAlertType.Amber}`]}, 
                false
            )
        );

        requestList.push(
            aldotManager.getAll(
                buildLastStateResponse(getState().alerts, "Aldot"),
                {"audience": [EATAudience.Dashboard]},
                false
            )
        );

        Promise.all(requestList).then(
            (responses: IProcessedResponse[]) => {
                if (responses[0].error)
                    dispatch(failure({ errorMessage: responses[0].error.message}))
                else if (responses[1].error)
                    dispatch(failure({ errorMessage: responses[1].error.message}));
                else {
                    let aleaData: any = responses[0].data;
                    let aldotData: any = responses[1].data;

                    let totalData: any[] = [];
                    if (aleaData) totalData = totalData.concat(aleaData);
                    if (aldotData) totalData = totalData.concat(aldotData);

                    dispatch(success({
                        data: totalData,
                        lastAleaResponse: responses[0].getReduxObject(),
                        lastAldotResponse: responses[1].getReduxObject(),
                        hasLongText: anyAlertHasLongText(totalData)
                    }))
                }
            }
        ).catch(
            (error: Error) => dispatch(failure({ errorMessage: error.message}))
        )
    }
};

export const getAll_test = (
    id: number = 12
) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());

        let alerts: IATAleaAlert[] = 
            T_FACILITIES[id as keyof typeof T_FACILITIES].aleaAlerts.map(
                (alert: IATAleaAlertDto) => 
                    new ATAleaAlert(alert as IATAleaAlertDto)
            );

        let messages: IATAldotMessage[] = 
            T_FACILITIES[id as keyof typeof T_FACILITIES].aldotMessages.map(
                (message: IATAldotMessageDto) => 
                    new ATAldotMessage(message as IATAldotMessageDto)
            );

        let testData: Array<IATAldotMessage | IATAleaAlert> = 
            [ ...alerts, ...messages];

        dispatch(success({
            data: testData, 
            hasLongText: anyAlertHasLongText(testData)
        }));
    } 
};

export const getAlerts = (id: number = 12) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        // check if a refresh was already queued up, if so cancel it now
        if (refreshTimeoutId){
            clearTimeout(refreshTimeoutId);
            refreshTimeoutId = null;
        }

        // note the id used for the request; in test mode this is necessary 
        // for making refresh calls (meaningless for real api calls)
        lastRequestId = id;

        if (isTesting)
            return dispatch(getAll_test(id));
        else 
            return dispatch(getAll_api(id));
    }
};

export const listReducer =  alertsSlice.reducer;


/******************************************************************************
    HELPERS
******************************************************************************/
// get the length of either text or body, depending on type given
export const getAlertTextLength = (alert: IATAldotMessage | IATAleaAlert) => {
    // text property can be 'text' or 'body' depending on the model type
    let text: string = 
        (alert as any).text || 
        (alert as any).body || 
        "";

    // find out how many characters are in the given text
    return text.length || 0;
};

// determine if ANY of the given alerts of either type have a text property 
// long enough to require an increase in card height
export const anyAlertHasLongText = (alerts: Array<IATAldotMessage | IATAleaAlert>) => {

    for (const alert of alerts){
        if (getAlertTextLength(alert) >= LONG_ALERT_TEXT)
                return true;
    }
    return false;  
}

export const buildLastStateResponse = ( state: any, type: string ): IProcessedResponse => {

    return new ProcessedResponse(
        {
            data: state[`last${type}Data`],
            errorMessage: state[`last${type}ErrorMessage`],
            status: state[`last${type}Status`],
            xChecksum: state[`last${type}Checksum`],
            xCount: state[`last${type}Count`]
        }
    )
};