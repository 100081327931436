import * as React from "react";

export type IErrorBoundaryState = {
    hasError: boolean;
    error?: Error;
}

export type IErrorBoundaryProps = {
    childName?: string;
    autoRefresh?: boolean;
    refreshDelay?: number;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState>{

    state: IErrorBoundaryState;

    constructor(props: IErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false, error: undefined };
    }
  
    static getDerivedStateFromError(error: any) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
      // log logic goes here if desired
      if (this.props.autoRefresh)
        setTimeout(
            () => {
                document.location.reload();
            }, 
            this.props.refreshDelay || 60000
        );
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        if (this.props.childName)
            return (
                <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center"}}>
                    <h1>{`Something went wrong with: ${this.props.childName}`}</h1>
                    <h3>{`Error: ${this.state.error?.message}`}</h3>
                </div>
            );
        else 
            return (
                <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center"}}>
                    <h1>{`Something went wrong.`}</h1>
                    <h3>{`Error: ${this.state.error?.message}`}</h3>
                </div>
            );
      }
  
      else return this.props.children; 
    }
  }