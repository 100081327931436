// types & models
import { IProcessedResponse, WeatherRadarNetworkManager } from "@algo/network-manager/managers/v3";
// libraries
import { createSlice } from "@reduxjs/toolkit";
// constants
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../api-endpoint-constants";
import { isTesting } from "~/constants";
import { nuonoe } from "@caps-mobile/common-lib";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).weatherRadar}/Keys`;

export type IWeatherRadarState = {
    data?: any[] | null,
    errorMessage?: string,
    noContent: boolean,
    
    lastData: any[] | null,
    lastErrorMessage: string,

    loading: boolean,
};

const initialState: IWeatherRadarState = {
    data: null,
    errorMessage: "",
    noContent:false,

    lastData: null,
    lastErrorMessage: "",

    loading: false,
};

// create list slice
export const weatherRadarSlice = 
    createSlice(
        {
            name: `weather-radar`,
            initialState,
            reducers: {
                begin: (state) =>  {
                    state.loading = true
                },
                success: (state, action) => {
                    state.data = action.payload.data;
                    state.errorMessage = action.payload.errorMessage;
                    state.noContent = !nuonoe(action.payload.data);

                    state.lastData = action.payload.data;
                    state.lastErrorMessage = action.payload.errorMessage;

                    state.loading = false;  
                },
                failure: (state, action) => {
                    state.data = [];
                    state.errorMessage = action.payload.errorMessage;

                    state.loading = false;
                }
            }
        }
    );

// get handles for the slice's actions
const { 
    begin, success, failure
} = weatherRadarSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getKey = (test: boolean = isTesting, testMode?: string) => {
    return (
        dispatch: any,
        getState: any
    ) => {
        console.log("getting keys");
        if (test)
            dispatch(getKey_test(testMode));
        else 
            dispatch(getKey_api());
    }
}

// retrieves data from api for this data type
export const getKey_api = () => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let manager = new WeatherRadarNetworkManager(apiUrl);
    
        manager.getKey()
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message} ))
                    else {
                        dispatch(success( { data: response.getReduxObject().data} ));
                    }
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message } ))
            )
        }
};

// retrieves test data for this data type
export const getKey_test = (mode?: string) => {
        
    return (
        dispatch: any,
        getState: any
    ) => {
    
        alert("routes: getKey_test is not yet implemented.");

    }
};

// exports the slice's reducer ( used in store file to build up master reducer )
export const weatherRadarReducer = weatherRadarSlice.reducer;