// libraries 
import { EATPlaceType, IATLocation } from "@algo/network-manager/models/v3";
import * as React from "react";
// styles
import * as SC from "./Styled";
// resources
//import RoadShield from "~/components/views/shields/RoadShield";
import { RoadShield } from "@caps-mobile/traffic-graphics";
import { useSelector } from "react-redux";
// constants

export type IProps = {
    routeNumbers: number[];
    shieldType: EATPlaceType;
    directionString: string;
    location?: IATLocation;
};

export const Sign: React.FC<IProps> = (props) => {

    const {
        routeNumbers, shieldType, directionString, location
    } = props;

    const pageControl: any = useSelector((state: any) => state["page-control"]);

    if (!location) return null;

    const isFourHigh: boolean = 
        pageControl.cardHeightMap["traffic-events"] === 4;

    const integerPart: string = 
        `${Math.floor(location.linearReference)}`;

    const decimalPart: string = 
        (location.linearReference % 1).toFixed(1).toString().split(".")[1];

    return (
        <SC.Sign isFourHigh={isFourHigh}>
            
            <SC.Text>
                {directionString}
            </SC.Text>

            <SC.Shields>
                { 
                    routeNumbers.map(
                        (shield: number, index: number) => {
                            return( 
                                <RoadShield 
                                    key={`${shield}${index}`}
                                    text={`${shield}`} type={shieldType}
                                    width={43} height={37}
                                    textSize={"22px"} textHeight={"22px"}
                                />
                            );
                        }
                    )
                }
            </SC.Shields>

            <SC.Text>Mile</SC.Text>

            <SC.Text>
                {integerPart}
            </SC.Text>


            {   isFourHigh && <SC.StyledDilineator /> }
            {   isFourHigh && <SC.Text>{`.${decimalPart}`}</SC.Text> }
            

        </SC.Sign>
    );
};

export default Sign;