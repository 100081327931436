// libraries
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
// store
import { 
    ActionCreator as EventActionCreator
} from "~/store/algo-api/traffic-events";
import { 
    ActionCreator as CameraActionCreator 
} from "../../../../store/algo-api/cameras";
import { 
    ActionCreator as TravelTimeActionAcreator
} from "../../../../store/algo-api/travel-times";
import {
    incrementIndex as incrementStateAlertIndex
} from "../../../../store/algo-api/state-alerts";
import {
    incrementIndex as incrementWeatherAlertIndex 
} from "../../../../store/algo-api/weather-alerts";
// constants
import { isTesting, listCycleInterval } from "../../../../constants";

// logic: repeating: causes cards/stacks to cycle through list items
export const useInitializeCycle = (facilityId: number | undefined) => {

    const dispatch = useDispatch();

    useEffect(
        () => {

            if (!facilityId) return;

            let cameraHandler = new CameraActionCreator(facilityId, isTesting);
            let eventHandler = new EventActionCreator(facilityId, isTesting);
            let travelTimeHandler = new TravelTimeActionAcreator(facilityId, isTesting);

            let alertIntervalId: NodeJS.Timeout;
            let travelTimeIntervalId: NodeJS.Timeout;
            let trafficEventIntervalId: NodeJS.Timeout;

            // immediately start the alerts and cameras cycle 
            alertIntervalId = setInterval(
                () => {
                    Promise.resolve().then(
                        () => {
                            dispatch(incrementStateAlertIndex);
                            dispatch(cameraHandler.incrementIndex());
                        }
                    );
                }, listCycleInterval
            );

            // wait 3 seconds and then start the travel times and weather alerts cycle
            let travelTimeTimeoutId = setTimeout(
                () => {
                    travelTimeIntervalId = setInterval(
                        () => {
                            Promise.resolve().then(() => {
                                dispatch(travelTimeHandler.incrementIndex());
                                dispatch(incrementWeatherAlertIndex);
                            });
                        }, 
                        listCycleInterval
                    );
                }, 3000
            );

            // wait 6 seconds and then start the remaining cycles
            let trafficEventTimeoutId = setTimeout(
                () => {
                    trafficEventIntervalId = setInterval(
                        () => {
                            Promise.resolve().then(
                                () => {
                                    dispatch(eventHandler.incrementIndex());
                                }
                            );
                        }, listCycleInterval 
                    );
                }, 6000
            );
    
            return () => {

                // clear timeouts
                if (travelTimeTimeoutId)
                    clearTimeout(travelTimeTimeoutId);
                if (trafficEventTimeoutId)
                    clearTimeout(trafficEventTimeoutId);
                if (travelTimeTimeoutId)
                    clearTimeout(travelTimeTimeoutId);

                // clear intervals
                if (alertIntervalId)
                    clearInterval(alertIntervalId);
                if (trafficEventIntervalId)
                    clearInterval(trafficEventIntervalId);
                if (travelTimeIntervalId)
                    clearTimeout(travelTimeIntervalId);
                    
            }
    
        }, [facilityId]
    );
};