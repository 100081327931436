// libraries
import { filterOutOfBoundsObjects } from "~/library";
import { nuon } from "@caps-mobile/common-lib";
// store
import { FilteredDataActionCreators, filteredDataSlice } from "./filtered-data-store";
// interfaces & models
import { 
    EATDirection, IATLocation, IATTrafficEvent 
} from "@algo/network-manager/models/v3";
import { 
    FacilityNetworkManager as NetworkManager, 
} from "@algo/network-manager/managers/v3";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../api-endpoint-constants";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).facilities}`;

// create abstract 'FilteredDataActionCreators' class
// this class will take care of creating a slice and actions as well
export class ActionCreator extends FilteredDataActionCreators {

    constructor(facilityId: number, testMode: boolean = false){
        
        super(
            "traffic-events",
            facilityId,
            new NetworkManager(apiUrl).getTrafficEvents,
            testMode
        );
    };

    // utilizes the here map view bounds data to filter
    // events which fall outside the geographical area currently in the map view
    filterData = () => {
        
        return (
            dispatch: any,
            getState: any
        ) => {
    
            let bounds = getState()["here-map-state"].bounds;
            let data = getState()["traffic-events"].data;
    
            if (bounds && bounds.length > 0 && data && data.length > 0){
                dispatch(this.actions.filterDataAction({
                    filteredData: splitEventsByDirection(
                        [...filterOutOfBoundsObjects(data, bounds, getEventCoords)]
                    )
                }));
            }

            else {
                dispatch(this.actions.filterDataAction({filteredData: data}));
            }
        }
    };

}

// transforms events with multiple directions into multiple, discrete events 
// this allows for displaying less information per card
export const splitEventsByDirection = (
    currentEventsList: IATTrafficEvent[]
): IATTrafficEvent[] => {

    let newEventsList: IATTrafficEvent[] = [];

    // check each event
    for (let i = 0; i < currentEventsList.length; i++){

        let event: IATTrafficEvent = currentEventsList[i];

        // if the event has no laneDirections, skip it
        if (event.laneDirections && event.laneDirections.length > 0){
            let directionsCount: number = event.laneDirections?.length;

            if (isMultiDirectional(event)){
                for (let j = 0; j < directionsCount; j++){
                    // if current direction nuon 
                    // append a copy of the event, but with ONLY the current direction
                    if (nuon(event.laneDirections[j])){
                        let newEvent: IATTrafficEvent = {
                            ...event,
                            laneDirections: [event.laneDirections[j]]
                        };
                        newEventsList.push(newEvent);
                    }
                }
            }
            else {
                // if the event is not multidirectional, we only need to create a single entry
                let newEvent: IATTrafficEvent = {
                    ...event,
                    laneDirections: [event.laneDirections[0]]
                };
                newEventsList.push(newEvent);
            }
        }
        else {
            continue;
        }
    }

    return newEventsList;
}

// determines whether a given trafficEvent has multiple valid directions
const isMultiDirectional = (trafficEvent: IATTrafficEvent): boolean => {

    let start: IATLocation | null = trafficEvent.startLocation || null;
    let end: IATLocation | null = trafficEvent.endLocation || null;

    let direction: EATDirection = EATDirection.Unknown;

    if (start)
        direction = start.direction;
    else if (end)
        direction = end.direction;
    
    switch(direction){
        case EATDirection.AllDirections:
        case EATDirection.Any:
        case EATDirection.EastWest:
        case EATDirection.NorthSouth:
            return true;
        default:
            return false;
    }
}

// returns a coordinates object representing the location of an event
export const getEventCoords = (
    event: IATTrafficEvent
): {lat: number, lng: number} | null => {

    let startLocation = event.startLocation;
    let endLocation = event.endLocation;
    
    if (startLocation && startLocation.latitude && startLocation.longitude)
        return {lat: startLocation.latitude, lng: startLocation.longitude}
    else if  (endLocation && endLocation.latitude && endLocation.longitude)
        return {lat: endLocation.latitude, lng: endLocation.longitude}

    return null;

}

export const trafficEventReducer = filteredDataSlice("traffic-events").reducer;