// libraries
import styled from "styled-components";
import { ellipsis, flexSmart } from "@caps-mobile/styled-lib";
import { CARD_PADDING } from "~/constants";

export const StyledTravelTimeWrapper = styled.div`
    height: 100%;
    width: 100%;

    box-sizing: border-box;

    ${flexSmart("space-between flex-start")}
    flex-direction: column;

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const StyledName = styled.div`
    width: 100%;
    height: auto;

    text-align: center;

    ${ellipsis()}
    ${props => props.theme.typography["heading-2.5"]}
`;

export const StyledBottomRow = styled.div`
    height: auto;
    ${flexSmart("flex-start center")}
`;

export const StyledShield = styled.div`
    ${flexSmart("flex-start center")}
    margin-right: ${CARD_PADDING}px;
`;

export const StyledTimeSpeedWrapper = styled.div`
    height: 100%;
    ${flexSmart("space-between flex-start")}
    flex-direction: column;
`;

export const StyledTimeSpeed = styled.div`
    width: 100%;
    height: auto;

    ${ellipsis()}

    ${props => props.theme.typography["heading-3"]}
`;